// ::: Auth
export const SET_USER = "SET_USER";
export const SET_ACCOUNT = "SET_ACCOUNT";
export const SIGNIN_INIT = "SIGNIN_INIT";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";

// ::: Utils
export const SET_SITE_LANG = "SET_SITE_LANG";
export const SET_PAGE_TITLE = "SET_PAGE_TITLE";
export const SET_SIDER_WIDTH = "SET_SIDER_WIDTH";
export const SET_PAGE_SUB_TITLE = "SET_PAGE_SUB_TITLE";

// ::: SMS
export const SET_SENDERIDS = "SET_SENDERIDS";
export const SET_SCHEDULES = "SET_SCHEDULES";