import React from "react";
import axios from "axios";
import { FormattedMessage } from "react-intl";
import {
  PageHeader,
  Menu,
  Icon,
  Dropdown,
  Layout,
  Drawer,
  Avatar,
  Modal,
  Button,
  notification,
  Spin,
} from "antd";
import { hp, cf } from "../../utils";

import "./assets/Header.scss";
import drawer from "./assets/drawer.svg";
import company from "./assets/company.png";

import EsokoLogo from "./assets/esoko.svg";
import InsytLogo from "./assets/insyt.svg";
import AnalyticsLogo from "./assets/analyticsLogo.svg";
import AbisLogo from "./assets/abisLogo.svg";
import PushLogo from "./assets/pushLogo.svg";
import PeopleLogo from "./assets/peopleLogo.svg";
import { useState } from "react";

const apps = [
  {
    name: "Insyt",
    url: process.env.REACT_APP_INSYT_APP_URL,
    logo: InsytLogo,
    access: "ACCESS_INSYT",
  },
  {
    name: "Analytics",
    url: process.env.REACT_APP_ANALYTICS_APP_URL,
    logo: AnalyticsLogo,
    access: "ACCESS_ANALYTICS",
  },
  {
    name: "ABIS",
    url: process.env.REACT_APP_ABIS_APP_URL,
    logo: AbisLogo,
    access: "ACCESS_ABIS",
  },
  {
    name: "Messaging",
    url: process.env.REACT_APP_PUSH_APP_URL,
    logo: PushLogo,
    access: "ACCESS_PUSH",
  },
  {
    name: "People",
    url: process.env.REACT_APP_PEOPLE_APP_URL,
    logo: PeopleLogo,
    access: "ACCESS_PEOPLE",
  },
  {
    name: "Account",
    url: process.env.REACT_APP_ACCOUNT_APP_URL,
    logo: EsokoLogo,
  },
];

class EsokoHeader extends React.PureComponent {
  state = {
    drawerShow: false,
    switcher: false,
    clickable: true,
    accounts: [],
    activeAccount: hp.isJson(hp.getCookie("active_account"))
      ? JSON.parse(hp.getCookie("active_account"))
      : {},
    inv_email: hp.getCookie("inv_email"),
  };

  componentDidMount() {
    const { activeAccount } = this.state;
    const {
      user: { user_id, user_preferences },
    } = this.props;

    if (activeAccount && activeAccount.id) {
      axios({
        method: "GET",
        url: `${cf.SSO_URL}/users/${user_id.trim()}/accounts`,
        headers: { "x-access-token": hp.getCookie("user_token") },
      }).then((response) => {
        const res = response.data;
        if (res.statusCode === 200) {
          this.setState({ accounts: res.data });
          const newAccount = res.data.filter(
            (row) => row.id === activeAccount.id
          )[0];
          hp.setCookie("active_account", JSON.stringify(newAccount));
          this.setState({ activeAccount: newAccount });
        }
      });

      // save active account
      if (activeAccount.id !== (user_preferences.active_account || {}).id) {
        axios({
          method: "PUT",
          url: `${cf.SSO_URL}/users/${user_id.trim()}`,
          headers: { "x-access-token": hp.getCookie("user_token") },
          data: {
            preferences: {
              ...user_preferences,
              language: hp.getCookie("site_lang"),
              active_account: activeAccount,
            },
          },
        });
      }
    } else {
      this._signout();
    }
  }

  _signout = () => {
    this.props.signOut();
  };

  render() {
    const { drawerShow, switcher, accounts, activeAccount } = this.state;
    const { app, user } = this.props;
    const inv_account =
      hp.getCookie("inv_account") && JSON.parse(hp.getCookie("inv_account"));

    const menu = (
      <Menu>
        <Menu.Item key="0">
          <div style={{ paddingRight: "2rem" }}>
            <p
              style={{
                textTransform: "uppercase",
                fontSize: ".90rem",
                fontWeight: "bold",
                marginBottom: 0,
              }}
            >
              {user && user.first_name} {user && user.last_name}
            </p>

            <span style={{ fontSize: ".75rem" }}>{user && user.email}</span>
          </div>
        </Menu.Item>
        <Menu.Divider />
        {accounts.length > 1 && (
          <Menu.Item
            key="switcher"
            onClick={() => this.setState({ switcher: true })}
          >
            <FormattedMessage
              id="SwitchOrganisation"
              defaultMessage="Switch Organisation"
            />
          </Menu.Item>
        )}
        {accounts.length > 1 && <Menu.Divider />}
        <Menu.Item style={{
          position : 'relative'
        }}  key="help_support"  >
          <FormattedMessage id="Help_support" defaultMessage="Help & Support" />
          <a style={{
            position : 'absolute' ,
            inset : '0',
          }} target={"_blank"} href={'https://www.insyt.esoko.com/support/'}></a>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="signout" onClick={() => this._signout()}>
          <Icon type="poweroff" />{" "}
          <FormattedMessage id="SignOut" defaultMessage="Sign Out" />
        </Menu.Item>
      </Menu>
    );

    return (
      <React.Fragment>
        {activeAccount && activeAccount.id && (
          <div>
            <div>
              <Layout.Header
                id="esoko___header"
                style={{ width: `calc(100%)` }}
              >
                <PageHeader
                  title={
                    <div>
                      <span
                        style={{ cursor: "pointer", marginRight: 12 }}
                        onClick={() => this.setState({ drawerShow: true })}
                      >
                        <img src={drawer} alt="Esoko Drawer" style={{}} />
                      </span>
                      <span style={{ fontSize: "1rem", fontWeight: 600 }}>
                        Esoko
                      </span>
                    </div>
                  }
                  extra={[
                    <Dropdown key={"user"} overlay={menu} trigger={["click"]}>
                      <div className="ant-dropdown-link">
                        <Avatar
                          sizes="small"
                          shape="circle"
                          src={activeAccount.logoUrl || company}
                          alt={activeAccount.name}
                          icon="user"
                        />
                        {truncateString(activeAccount.name, 50)} &nbsp;
                        <span className="texticon">
                          <Icon type="down" />
                        </span>
                      </div>
                    </Dropdown>,
                  ]}
                ></PageHeader>
              </Layout.Header>

              <Drawer
                title={null}
                placement="left"
                closable={false}
                onClose={() => this.setState({ drawerShow: false })}
                visible={drawerShow}
                id="esoko___drawer"
              >
                <div>
                  {apps.map(
                    (ap) =>
                      (app || "").toLowerCase() !== ap.name.toLowerCase() &&
                      (!ap.access ||
                        (ap.access && hp.hasP(ap.access, user.role))) && (
                        <div
                          key={ap.name}
                          className="MenuGridList"
                          onClick={() => {
                            ap.url && window.location.assign(ap.url);
                          }}
                        >
                          <div className="MenuGridItem">
                            <img
                              src={ap.logo}
                              alt={ap.name}
                              height={
                                ap.name.toLowerCase() === "account"
                                  ? "40px"
                                  : "24px"
                              }
                            />
                            <p style={{ marginTop: 5 }}> {ap.name}</p>
                          </div>
                        </div>
                      )
                  )}
                </div>
              </Drawer>

              <Modal
                onCancel={() => this.setState({ switcher: false })}
                centered={true}
                closable={false}
                maskClosable={false}
                visible={switcher || activeAccount.id === undefined}
                width={400}
                className="esoko__acc__switcher"
                title={
                  <div>
                    <b>
                      <FormattedMessage
                        id="Header"
                        defaultMessage="Choose an organization to switch into"
                      />
                    </b>
                    {activeAccount.id && (
                      <div>
                        <div className="current">Current Organisation</div>
                        <SwitcherAccountCard
                          account={activeAccount}
                          activeAccount={activeAccount}
                          user={user}
                          active={true}
                        />
                      </div>
                    )}
                  </div>
                }
                footer={[
                  activeAccount.id && (
                    <Button
                      type="link"
                      onClick={() => this.setState({ switcher: false })}
                    >
                      Cancel
                    </Button>
                  ),
                ]}
              >
                {accounts.length > 1 &&
                  accounts.map((account) => (
                    <SwitcherAccountCard
                      key={account.id}
                      account={account}
                      activeAccount={activeAccount}
                      clickable={this.state.clickable}
                      user={user}
                      onSwitching={(clickable) => this.setState({ clickable })}
                    />
                  ))}
              </Modal>
            </div>

            <Modal
              title={null}
              width={400}
              visible={this.state.inv_email ? true : false}
              closable={false}
              maskClosable={false}
              centered={true}
              onCancel={() => this.setState({ inv_email: false })}
              footer={[
                <Button
                  type="primary"
                  onClick={() => {
                    this.setState({ inv_email: false });
                    clearInvAccount();
                  }}
                >
                  Proceed
                </Button>,
              ]}
            >
              <FormattedMessage
                id="Inivitation.Accept.AfterMsg"
                defaultMessage={
                  "Congratulations! You are now a member of {name}'s organization."
                }
                values={{ name: inv_account.name }}
              />
            </Modal>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default EsokoHeader;

const clearInvAccount = () => {
  hp.deleteCookie("inv_email");
  hp.deleteCookie("inv_person");
  hp.deleteCookie("inv_account");
};

const SwitcherAccountCard = (props) => {
  const { account, active, activeAccount, user, onSwitching } = props;
  const [spinning, setSpinning] = useState(false);
  const onClick = () => {
    if (props.clickable) {
      setSpinning(true);
      onSwitching(false);
      Promise.all([
        axios({
          method: "POST",
          url: `${cf.SSO_URL}/auth/account-logins`,
          headers: { "x-access-token": hp.getCookie("user_token") },
          data: {
            accountId: account.id,
            userId: user.user_id,
          },
        }),
        axios({
          method: "GET",
          url: `${cf.SSO_URL}/user-accounts/${user.user_id}/${account.id}`,
          headers: { "x-access-token": hp.getCookie("user_token") },
        }),
      ])
        .then((res) => {
          const accountLoginsRes = res[0].data;
          const userAccountsRes = res[1].data;
          if (
            accountLoginsRes.statusCode === 200 &&
            userAccountsRes.statusCode === 200
          ) {
            hp.setCookie("user_token", accountLoginsRes.token);
            hp.setCookie("project_id", account.id);
            hp.setCookie("active_account", JSON.stringify(account));
            hp.setCookie(
              "user",
              JSON.stringify({
                ...user,
                role: userAccountsRes.data.role,
              })
            );
            window.location.assign(
              `/${user.user_preferences.language || "en"}/forms`
            );
          } else {
            notification.error({ message: "Unable to switch account" });
          }
        })
        .catch(() => {
          setSpinning(false);
          onSwitching(true);
          notification.error({ message: "Unable to switch account" });
        });
    }
  };

  return account.name && (active || account.id !== activeAccount.id) ? (
    <Spin
      size="small"
      spinning={spinning}
      indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
    >
      <div className={`caard ${!active ? "active" : ""}`} onClick={onClick}>
        <div className="logo">
          <img src={account.logoUrl || company} alt={account.name} />
        </div>
        <div className="text">
          <div>
            <div className="title">{account.name}</div>
            <div className="admin">{account.industry}</div>
          </div>
        </div>
        <div className="clear"></div>
      </div>
    </Spin>
  ) : null;
};

const truncateString = (str, num) => {
  if (str) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  }
};
