import React from 'react';
import { FormattedMessage } from "react-intl";
import Esoko from '../../assets/img/auth/esoko.svg';


const AuthHeader = props => {
    const { screen } = props;

    return (
        <div id="auth__header">
            <img src={Esoko} alt="Esoko" />
            <div>&nbsp;</div>

            <div className="title">
                {screen === 'signin' && (<FormattedMessage id="auth_signin_title" defaultMessage="Sign in to your account" />)}
                
                {screen === 'signup' && (<FormattedMessage id="auth_signup_title" defaultMessage="Sign up today. Absolutely free!" />)}
            </div>
            <p>&nbsp;</p><p>&nbsp;</p>
        </div>
    )
};

export default AuthHeader;