import { REACT_APP_COOKIES_DOMAIN, STORAGE_PRFX } from "./config";
import moment from "moment";
import "moment-timezone";
import ct from "countries-and-timezones";

// ::: cookie
export const setCookie = (cname, cvalue, exdays = 7) => {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = `${
    STORAGE_PRFX + cname
  }=${cvalue};${expires};path=/;domain=${REACT_APP_COOKIES_DOMAIN}`;
};

export const getCookie = (cname) => {
  var name = STORAGE_PRFX + cname + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      if (c.split("=")[1].length !== 0) {
        return c.substring(name.length, c.length);
      }
    }
  }
  return "";
};

export const deleteCookie = (cname) => {
  var expires = "expires=-1";
  document.cookie = `${
    STORAGE_PRFX + cname
  }=;${expires};path=/;domain=${REACT_APP_COOKIES_DOMAIN}`;
};

// ::: storage
export const setStorage = (name, value) => {
  return localStorage.setItem(STORAGE_PRFX + name, value);
};
export const getStorage = (name, value) => {
  return localStorage.getItem(STORAGE_PRFX + name, value);
};
export const delStorage = (name) => {
  return localStorage.removeItem(STORAGE_PRFX + name);
};

export const titleCase = (str) => {
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};
export const numberFormatter = (x) => {
  return x
    .toFixed(4)
    .toString()
    .replace(/\B(?=(\d{5})+(?!\d))/g, ",");
};

export const truncate = (str, n) => {
  return str.slice(0, n);
};

export const IsStringJSON = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getTimeZone = (ccc) => {
  // let as = '';
  const countries = ct.getAllCountries();
  let d = Object.values(countries);
  let aa = d.find((e) => e.name === ccc);

  if (aa) {
    return aa.timezones[0];
  } else {
    return moment.tz.guess();
  }
};

export const generateOptions = (
  length,
  disabledOptions,
  hideDisabledOptions,
  step = 1
) => {
  const arr = [];
  for (let value = 0; value < length; value += step) {
    if (
      !disabledOptions ||
      disabledOptions.indexOf(value) < 0 ||
      !hideDisabledOptions
    ) {
      arr.push(value);
    }
  }
  return arr;
};

export const mergeObj = (obj, src) => {
  for (var key in src) {
    if (src.hasOwnProperty(key)) obj[key] = src[key];
  }
  return obj;
};
// check permission
export const hasP = (permission, myRole) => {
  const thisRole = JSON.parse(getCookie("user") || "{}").role;
  if (thisRole) {
    var check = (process.env[`REACT_APP_${permission}`] || "")
      .split(",")
      .findIndex((role) => role === thisRole);
    // console.log(thisRole, permission, process.env[`REACT_APP_${permission}`], check);
    if (check === -1) {
      return false;
    }
    return true;
  }
  return false;
};

export const canAccessDashboard = (role) =>
  ["manager", "collaborator"].includes(role);
export function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function formatNumber(n) {
  if (n >= 1000000) {
    return (n / 1000000).toFixed(1) + "M";
  } else if (n >= 1000) {
    return (n / 1000).toFixed(1) + "k";
  } else {
    return (n || 0).toString();
  }
}
