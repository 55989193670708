import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from 'react-intl';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

// import { cf } from "../../utils";
import * as authAct from "../../store/auth/_authActions";
import * as utilsAct from "../../store/utils/_utilsActions";

import "./AuthContainer.scss";
import LoginMiddle from '../../assets/img/auth/login_middle.png';

import SignInForm from "../AuthScreens/Signin";
import SignUpForm from "../AuthScreens/Signup";
import ResetForm from "../AuthScreens/Reset";
import { Select } from "antd";

class AuthContainer extends React.Component {

  constructor(props) {
    super(props);

    this.props.signOut();
    throw '';
  }

  render() {
    const { utils, auth, intl, signIn, signUp, resetPwd } = this.props;
    return (
      <div className="authWrapper">
        <div className="authWrapper__Body">
          <div className="authWrapper__FormCard">
            <div className="authWrapper__FormCardLeft">
              <div>
                <img src={LoginMiddle} alt="Esoko" style={{ width: '65%', marginLeft: '10%' }} className="animated fadeIn delay-1s" />
                <div className="h1 animated fadeIn delay-1s">
                  <FormattedMessage id="Auth.Text1" defaultMessage="Empowering your business with innovative <br /> digital solutions and services" />

                </div>
                <div className="h2 animated fadeIn delay-1s">
                  DATA COLLECTION | FIELD DEPLOYMENT | AGRICULTURAL SERVICES
                </div>
              </div>
            </div>
            <div className="authWrapper__FormCardRight">
              <div className="formContainer">
                <Router>
                  <Switch>
                    <Route exact path="/:lang/signin" render={() => <SignInForm utils={utils} auth={auth} intl={intl} signIn={signIn} />} />
                    <Route exact path="/:lang/signup" render={() => <SignUpForm utils={utils} auth={auth} intl={intl} signUp={signUp} />} />
                    <Route exaxt path="/:lang/reset" render={() => <ResetForm utils={utils} auth={auth} intl={intl} resetPwd={resetPwd} />} />
                    <Route exaxt path="/:lang/resetverify/:resetToken" render={(props) => <ResetForm {...props} utils={utils} auth={auth} intl={intl} resetPwd={resetPwd} />} />

                    <Redirect to={`/${utils.lang}/signin`} />
                  </Switch>
                </Router>
              </div>
              <div style={{ textAlign: 'center', marginTop: '20%', fontSize: 13, width: '40%', marginLeft: '13%' }}>
                <Select className="switch__lang" defaultValue={this.props.utils.lang} size="small" onChange={(lang) => { this.props.setLang(lang); }}>
                  <Select.Option value="en">English</Select.Option>
                  <Select.Option value="fr">Français</Select.Option>
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  utils: state.utils
});

const mapDispatchToProps = (dispatch) => ({
  signIn: (int, lang, data) => {
    dispatch(authAct.signIn(int, lang, data))
  },
  signUp: (int, lang, data) => {
    dispatch(authAct.signUp(int, lang, data))
  },
  setLang: (lang) => {
    dispatch(utilsAct.setLang(lang))
  },
  signOut: () => {
    dispatch(authAct.signOut())
  },
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AuthContainer));