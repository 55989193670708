import { cf, hp } from '../utils';
import axios from 'axios';

const dashParams = [
  {
    report_type: 'form_response_counts',
    source: 'insyt',
    dataId: 'form-stats',
  },
  {
    report_type: 'sms_voice_count',
    source: 'push',
    dataId: 'push-stats',
  },
  {
    report_type: 'group_person_count',
    source: 'people',
    dataId: 'people-stats',
  },
  {
    report_type: 'recent_groups',
    source: 'people',
    dataId: 'people-recent',
  },
  {
    report_type: 'recent_sms_voice',
    source: 'push',
    dataId: 'push-recent',
  },
  {
    report_type: 'recent_forms_responses',
    source: 'insyt',
    dataId: 'form-recent',
  },
];

const dashboardInitial = {
  data: dashParams.reduce((acc, item) => {
    return {
      ...acc,
      [item.dataId]: {
        results: undefined,
        isLoading: true,
      },
    };
  }, {}),
};

const SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA';

export function getDashBoardData() {
  return dispatch => {
    const dispatchData = (results, dataId, isLoading) => {
      const parseResult =
        typeof results === 'string' ? JSON.parse(results) : results;
      dispatch({
        type: SET_DASHBOARD_DATA,
        payload: {
          data: {
            isLoading,
            results: parseResult,
          },
          dataId,
        },
      });
    };

    Promise.all(
      dashParams.map(item => {
        return new Promise(resolve => {
          axios({
            method: 'GET',
            url: `${cf.SSO_URL}/reports`,
            params: { report_type: item.report_type, source: item.source },
            headers: { 'x-access-token': hp.getCookie('user_token') },
          })
            .then(res => {
              dispatchData(res.data.data, item.dataId, false);
              resolve();
            })
            .catch(err => {
              dispatchData([], item.dataId, false);
              resolve();
            });
        });
      })
    ).then();
  };
}

export default (state = dashboardInitial, action) => {
  switch (action.type) {
    case SET_DASHBOARD_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.dataId]: action.payload.data,
        },
      };
    default:
      return { ...state };
      break;
  }
};
