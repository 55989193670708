import { SIGNIN_INIT, SIGNIN_SUCCESS, SIGNOUT_SUCCESS, SET_USER, SET_ACCOUNT } from '../_types';
import { hp } from '../../utils';


const token = hp.getCookie('user_token') || '';
const user = hp.isJson(hp.getCookie('user')) ? JSON.parse(hp.getCookie('user')) : {};
const account = hp.isJson(hp.getCookie('active_account') )? JSON.parse(hp.getCookie('active_account')) : {};

const initialState = {
    user,
    token,
    account,
    authenticated: token && user.user_id ? true : false,
    signingIn: false
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;

        case SIGNIN_INIT:
            return {
                ...state,
                signingIn: action.payload
            };

        case SIGNIN_SUCCESS:
            return {
                ...state,
                authenticated: true,
                token: action.payload.token,
                user: action.payload.data
            };

        case SIGNOUT_SUCCESS:
            return {
                ...state,
                user: {},
                token: '',
                authenticated: false
            };

        case SET_USER:
            return {
                ...state,
                user: action.data
            };

        case SET_ACCOUNT:
            return {
                ...state,
                account: action.data
            };
    }
};




export default authReducer;