import React, { PureComponent } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Row, Col, notification } from 'antd';
import { FormattedMessage } from "react-intl";
import { cf, cw } from "../../utils";

import BrokenLink from '../../assets/img/card.svg';
import ResetSuccess from '../../assets/img/reset_success.svg';


class Reset extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            authType: 'msisdn', resetToken: this.props.match ? this.props.match.params.resetToken : '', validx: true, reseting: false, reseted: false
        };
    }

    componentWillMount() {
        this.verifyResetToken();
    }

    asyncEmail = (rule, value, callback) => {
        if (!value) {
            callback();
        }
        if (value) {
            return fetch(`${cf.SSO_URL}/auths/users?email=${value}`, {
                headers: new Headers({
                    Accept: 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            }).then(response => response.json()).then(json => {
                if (json.statusCode !== 200) {
                    callback(`${value} does not exist`);
                } else {
                    callback();
                }
            }).catch(error => {
                callback(`${value} does not exist`);
            });
        }
    };

    _reset = e => {
        e.preventDefault();
        const { form: { validateFields }, intl, utils: { lang } } = this.props;
        validateFields((err, vals) => {
            if (!err) {
                this.setState({ reseting: true });
                axios({
                    method: 'POST',
                    url: `${cf.SSO_URL}/auths/forgot`,
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Origin': window.location.origin
                    },
                    data: {
                        userName: vals.userName,
                        language: lang || 'en'
                    }
                }).then((response) => {
                    this.setState({ reseting: false });
                    const res = response.data;
                    switch (res.statusCode) {
                        default:
                            notification.error({
                                message: intl.formatMessage(cw['Failed']),
                                description: 'Unable to reset password. Please try again'
                            });
                            break;
                        case 200:
                            this.setState({ reseted: true });
                            notification.success({
                                message: intl.formatMessage(cw['Success']),
                                description: intl.formatMessage(cw.resetEmailSent)
                            });
                            break;
                    }
                }).catch((err) => {
                    this.setState({ reseting: false });
                    notification.error({
                        message: intl.formatMessage(cw['Failed']),
                        description: 'Unable to reset password. Please try again'
                    });
                });
            }
        });
    };


    // ::: verify actions
    verifyResetToken = () => {
        const { resetToken } = this.state;
        if (resetToken) {
            axios({
                method: 'GET',
                url: `${cf.SSO_URL}/auths/resetverify/${resetToken}`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                if (response.data.statusCode !== 200) {
                    this.setState({ validx: false });
                }
            }).catch(() => {
                this.setState({ validx: false });
            });
        }
    }
    confirmPasswordMatch = (rule, value, callback) => {
        if (!value) {
            callback();
        }
        if (value !== this.props.form.getFieldValue('password')) {
            callback('Passwords do not match');
        }
        callback();
    }
    _verify = (e) => {
        e.preventDefault();
        const { form: { validateFields }, intl } = this.props;
        validateFields((err, v) => {
            if (!err) {
                this.setState({ reseting: true });
                axios({
                    method: 'POST',
                    url: `${cf.SSO_URL}/auths/passwordreset/${this.state.resetToken}`,
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    data: v
                }).then(response => {
                    this.setState({ reseting: false });
                    const res = response.data;
                    if (res.statusCode === 200) {
                        notification.success({ message: intl.formatMessage(cw['Success']), description: intl.formatMessage(cw.passwordChanged) });
                        setTimeout(() => {
                            window.location.assign('/signin');
                        }, 400);
                    } else {
                        notification.error({ message: intl.formatMessage(cw['Failed']), description: intl.formatMessage(cw.resetEmailFailed) });
                    }
                });
            }
        });
    }


    render() {
        const { resetToken, validx, reseting, reseted } = this.state;
        const { form: { getFieldDecorator, getFieldValue }, utils, intl } = this.props;

        return (
            <React.Fragment>

                {/* ::: reset */}
                {!resetToken && (
                    <Form id="esoko__signin" hideRequiredMark={true} onSubmit={this._reset}>
                        <div style={{ fontWeight: '400', marginBottom: '.5em', fontSize: 22 }}><FormattedMessage id="auth_reset_title" defaultMessage="Reset Password" /></div>
                        {reseted === false && (
                            <p className="auth_form_description">
                                <FormattedMessage id="Reset.Title.One" defaultMessage="Fill in your username below and we will send you further instructions on resetting your password." />
                            </p>
                        )}

                        {reseted === true && (
                            <p className="auth_form_description" style={{ background: '#E4EFFF' }}>
                                <div style={{ float: 'left' }}>
                                    <img src={ResetSuccess} alt="ResetSuccess" />
                                </div>
                                <div style={{ float: 'right', width: '85%' }}>
                                    <FormattedMessage id="Reset.Title.Two" defaultMessage="Further instructions have been sent to your e-mail address." />
                                </div>
                                <div style={{ clear: 'both' }}></div>
                            </p>
                        )}

                        <Row gutter={16}>
                            <Col span={24}>
                                <div>
                                    <Form.Item colon={false} label={intl.formatMessage(cw['Label.EmailAddress'])}>
                                        {getFieldDecorator('userName', {
                                            rules: [
                                                { trigger: 'onChange', required: true, message: intl.formatMessage(cw.formErrorMsgInputEmail) },
                                                { trigger: 'onBlur', validator: this.asyncEmail }
                                            ]
                                        })(
                                            <Input size="large" type="text" autoComplete="off" autoFocus />
                                        )}
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                        <Form.Item>
                            <Button className="submitBtn" block size="large" type="primary" htmlType="submit" disabled={!getFieldValue('userName')} loading={reseting}>
                                <FormattedMessage id="Button.Reset" defaultMessage="Reset Password" />
                            </Button>
                        </Form.Item>

                        <div className="formLinks">
                            <p style={{ paddingTop: '5%' }}>
                                <span className="noAccountText"><FormattedMessage id="Button.AlreadyHaveAccount" defaultMessage="Already have an account?" /></span>
                                <Link to={`/${utils.lang || 'en'}/signin`}><FormattedMessage id="Button.SignIn" defaultMessage="Sign in" /></Link>
                            </p>
                        </div>
                    </Form>
                )}


                {/* ::: verify */}
                {resetToken && !validx && (
                    <div style={{ textAlign: 'center' }}>
                        <img src={BrokenLink} alt="broken link" width="60" style={{ marginBottom: '1rem' }} />
                        <p style={{ fontSize: '24px' }}>
                            <FormattedMessage defaultMessage="Password reset request expired" id="App.Text.PWordlinkExpired" />
                        </p>
                        <div className="auth_form_links">
                            <p style={{ paddingTop: '10%' }}>
                                <Link to={`/${utils.lang}/reset`} onClick={() => this.setState({ resetToken: '' })}>
                                    <FormattedMessage id="resetPassword.resetRequestMsg" defaultMessage={'Request New Password Reset Or Token'} />
                                </Link>
                            </p>
                        </div>
                    </div>
                )}

                {resetToken && validx && (
                    <Form onSubmit={this._verify} className="resetPassword" hideRequiredMark>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item colon={false} label={<FormattedMessage id="loginForm.PasswordInput" defaultMessage={'New Password'} />}>
                                    {getFieldDecorator('password', {
                                        rules: [
                                            { required: true, message: intl.formatMessage(cw.formErrorMsgInputPassword) },
                                            { min: 6, message: intl.formatMessage(cw.minPasswordChar) }
                                        ]
                                    })(<Input size="large" type="password" placeholder="" />)}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item colon={false} label={<FormattedMessage id="loginForm.ConfirmPasswordInput" defaultMessage={'Confirm New Password'} />}>
                                    {getFieldDecorator('passConfirm', {
                                        rules: [
                                            { required: true, message: intl.formatMessage(cw.formErrorMsgConfirmPassword) },
                                            { validator: this.confirmPasswordMatch },
                                            { min: 6, message: intl.formatMessage(cw.minPasswordChar) }
                                        ]
                                    })(<Input size="large" type="password" placeholder="" />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <div style={{ marginTop: '.7rem', marginBottom: '1rem' }}>
                            <Button block type="primary" size="large" htmlType="submit" className="reset-form-button" loading={reseting}>
                                <FormattedMessage id="resetPassword.ResetButton" defaultMessage={'Reset Password'} />
                            </Button>
                        </div>
                        <div className="auth_form_links">
                            <p style={{ paddingTop: '10%' }}>
                                <FormattedMessage id="loginScreen.HaveAccountText" defaultMessage={'Already have an account?'} />{' '}
                                <Link to={`/${this.props.siteLanguage || 'en'}/signin`}>
                                    <FormattedMessage id="App.Text.Signin" defaultMessage={'Sign in'} />
                                </Link>
                            </p>
                        </div>
                    </Form>
                )}
            </React.Fragment>
        );
    }
}

const ResetForm = Form.create({ name: 'reset' })(Reset);
export default ResetForm;