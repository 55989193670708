import React, { Fragment } from "react";
import { Row, Col } from "rsuite";
import { FormattedMessage } from "react-intl";
import { cw } from "../../../utils";
import { Button, Dropdown, Icon, Menu } from "antd";

function UserItem({ isActive, user, onRoleClick, onRevokeUser, className }) {
  const { role, email, firstName, lastName, name } = user;
  return (
    <Row className={className}>
      <Col lg={7} className="person">
        {!isActive && (
          <Fragment>
            <div className={`avatar`}>
              {name.split(" ")[0][0]}
              {(name.split(" ")[1] || "")[0]}
            </div>
            <div className="name middle">{name}</div>
          </Fragment>
        )}
        {isActive && (
          <Fragment>
            <div className={`avatar`}>
              {firstName[0]}
              {lastName[0]}
            </div>
            <div className="name middle">
              {firstName} {lastName}
            </div>
          </Fragment>
        )}
        <div className="clear" />
      </Col>
      <Col lg={9}>{email}</Col>
      <Col lg={6}>
        {!isActive ? (
          <FormattedMessage {...cw[role || "manager"]} />
        ) : (
          <span>
            <span
              role="button"
              className="role"
              onClick={() => onRoleClick(user)}
              sso-id="user-change-roles-trigger"
            >
              <FormattedMessage {...cw[role || "manager"]} />
            </span>
          </span>
        )}
      </Col>
      <Col sm={1}>
        <Dropdown
          overlay={
            <Menu sso-id="administrators-revoke-button">
              <Menu.Item onClick={() => onRevokeUser(user.id)}>
                <FormattedMessage
                  id="RevokeAccess"
                  defaultMessage="Revoke Access"
                />
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <Button
            type="default"
            htmlType="button"
            shape="link"
            ghost
            sso-id="administrators-revoke-trigger"
          >
            <Icon type="ellipsis" style={{ transform: "rotate(90deg)" }} />
          </Button>
        </Dropdown>
      </Col>
    </Row>
  );
}

export default React.memo(UserItem);
