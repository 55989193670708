export const SSO_URL = process.env.REACT_APP_API_URL ||
    // 'http://192.168.3.190:9077/api/v2' ||
    'http://accounts-sso.qa.esoko.com:5050/api/v2' ||
    'http://accounts-api.qa.esoko.com:5050/v1';

export const REACT_APP_VERSION = require("../../package.json").version;
export const REACT_APP_COOKIES_DOMAIN = process.env.REACT_APP_COOKIES_DOMAIN || window.location.hostname;
export const REACT_APP_NODE_ENV = process.env.REACT_APP_NODE_ENV || "development";
export const REACT_INTERCOM_APP_ID = process.env.REACT_INTERCOM_APP_ID || "jxm2i8o8";
export const REACT_GOOGLE_MAP_API = process.env.REACT_GOOGLE_MAP_API || "AIzaSyCSjXAyUEZPiaycd0AkSlYoW2byopYtdVQ";
export const REACT_RECAPTCHA_SITE_KEY = process.env.REACT_RECAPTCHA_SITE_KEY || "6LcV2uYUAAAAALcqj-2_I1L7UTZn1tiNDVkRXEDy";
export const REACT_RECAPTCHA_SECRET_KEY = process.env.REACT_RECAPTCHA_SECRET_KEY || "6LcV2uYUAAAAAJuio3UhdX685RQ_sZJtoa8GqdpW";
export const STORAGE_PRFX = process.env.REACT_APP_STORAGE_PREFIX || 'esk_';
export const SYSTEM_ROLES = ['manager' , 'collaborator' , 'quality_assurance' ,  'general_viewer' , 'dashboard_viewer']
