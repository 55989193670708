import FormsIcon from "../../assets/img/apps/forms.svg";
import { FormattedMessage } from "react-intl";
import AnalyticsIcon from "../../assets/img/apps/analytics.svg";
import PushIcon from "../../assets/img/apps/push.svg";
import PeopleIcon from "../../assets/img/apps/people.svg";
import AbisIcon from "../../assets/img/apps/abis.svg";
import React from "react";

export const apps = [
  {
    icon: FormsIcon,
    name: "Insyt",
    description: (
      <FormattedMessage
        id="Insyt.Description"
        defaultMessage="Set up questions and collect data with ease using any mobile device."
      />
    ),
    url: process.env.REACT_APP_INSYT_APP_URL,
    dfs: "home_app_insyt",
    access: "ACCESS_INSYT",
  },
  {
    icon: PushIcon,
    name: "Messaging",
    description: (
        <FormattedMessage
            id="Push.Description"
            defaultMessage="Send bulk messages to your contacts, customers at affordable SMS rates."
        />
    ),
    url: process.env.REACT_APP_PUSH_APP_URL,
    dfs: "home_app_push",
    access: "ACCESS_PUSH",
  },
  {
    icon: PeopleIcon,
    name: "People",
    description: (
        <FormattedMessage
            id="People.Description"
            defaultMessage="Manage your people by importing their information into the People"
        />
    ),
    url: process.env.REACT_APP_PEOPLE_APP_URL,
    dfs: "home_app_people",
    access: "ACCESS_PEOPLE",
  },
  {
    icon: AnalyticsIcon,
    name: "Analytics",
    description: (
      <FormattedMessage
        id="Analytics.Description"
        defaultMessage="Analyze data with powerful features and simplified charts."
      />
    ),
    url: process.env.REACT_APP_ANALYTICS_APP_URL,
    dfs: "home_app_analytics",
    access: "ACCESS_ANALYTICS",
  },
  {
    icon: AbisIcon,
    name: "Abis",
    description: (
      <FormattedMessage
        id="Abis.Description"
        defaultMessage="Manage your people by importing their information into the People"
      />
    ),
    url: process.env.REACT_APP_ABIS_APP_URL,
    dfs: "home_app_abis",
    access: "ACCESS_ABIS",
  },
];

export const dashBoardStats = [
  {
    dataId: "form-stats",
    title: "Insyt",
    icon: FormsIcon,
    url: process.env.REACT_APP_INSYT_APP_URL,
    labels: [
      {
        title: "Forms",
        color: "#F8C07F",
        dataId: "formcount",
      },
      {
        title: "Responses",
        color: "#FB896B",
        dataId: "responsecount",
      },
    ],
  },
  {
    title: "Messaging",
    icon: PushIcon,
    url: process.env.REACT_APP_PUSH_APP_URL,
    dataId: "push-stats",
    labels: [
      {
        title: "SMS",
        color: "#F8C07F",
        dataId: "sms_count",
      },
      {
        title: "Voice",
        color: "#FB896B",
        dataId: "voice_count",
      },
    ],
  },
  {
    url: process.env.REACT_APP_PEOPLE_APP_URL,
    title: "People",
    icon: PeopleIcon,
    dataId: "people-stats",
    labels: [
      {
        title: "Groups",
        color: "#F8C07F",
        dataId: "groups_count",
      },
      {
        title: "People",
        color: "#FB896B",
        dataId: "persons_count",
      },
    ],
  },
];

export const dashBoardRecent = [
  {
    title: "Forms",
    exploreText: "View Forms",
    exploreUrl: "",
    color: "#1753a6",
    dataId: "form-recent",
    nameKey: "title",
    countKey: "count",
    countName: "Responses",
    url: process.env.REACT_APP_INSYT_APP_URL,
    getHref: (item, local = "en") =>
      `${process.env.REACT_APP_INSYT_APP_URL}/${local}/forms/${item.id}/responses`,
  },
  {
    title: "Messaging Campaigns",
    exploreText: "View Campaigns",
    exploreUrl: "reports",
    url: process.env.REACT_APP_PUSH_APP_URL,
    color: "#c76b1e",
    dataId: "push-recent",
    nameKey: "name",
    countKey: "recipients",
    countName: "Recipients",
    getHref: (item, local = "en") =>
      `${process.env.REACT_APP_PUSH_APP_URL}/${local}/reports/${
        item.type == "voice" ? "voice" : "sms"
      }/${item.id}`,
  },
  {
    title: "Groups",

    exploreText: "View Groups",
    exploreUrl: "",
    color: "#2265d9",
    dataId: "people-recent",
    nameKey: "name",
    countKey: "person_count",
    url: process.env.REACT_APP_PEOPLE_APP_URL,
    countName: "Persons",
    getHref: (item, local = "en") =>
      `${process.env.REACT_APP_PEOPLE_APP_URL}/${local}/groups/${item.group_id}`,
  },
];
//groups
