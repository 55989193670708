import React from "react";
import { connect } from "react-redux";

import DocumentTitle from "react-document-title";
import localeIntl from "./intl/data.json";
import { IntlProvider, addLocaleData } from "react-intl";

import en from "react-intl/locale-data/en";
import fr from "react-intl/locale-data/fr";

import "./assets/css/antd.scss";
import "./assets/css/animate.css";
import "./assets/css/responsive.scss";

import * as authAct from "./store/auth/_authActions";

import AppContainer from "./screens/AppContainer";
import AuthContainer from "./screens/AuthContainer/AuthContainer";
import Axios from "axios";
import { hp, cf } from "./utils";
import { getDashBoardData } from "./store/dashboard";

addLocaleData([...en, ...fr]);

const LoadingApp = () => (
  <div style={{ marginTop: "20%", textAlign: "center" }}>
    <div>
      <img
        src="/assets/esoko.ico"
        alt="Esoko"
        width="40px"
        className="animated fadeIn fasts infinite"
      />{" "}
      <br /> initializing...
    </div>
  </div>
);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pathname: "",
      loading: true,
    };
  }

  componentDidMount() {
    let user = hp.getCookie("user");
    let account = hp.getCookie("account");
    if (user && account === "") {
      user = JSON.parse(user);
      Axios({
        method: "GET",
        url: `${cf.SSO_URL}/accounts/${user.account_id}`,
        headers: {
          "x-access-token": `${hp.getCookie("user_token")}`,
        },
      })
        .then((res) => {
          if (res.data.statusCode === 200) {
            hp.setCookie("account", JSON.stringify(res.data.data));
            this.props.setAccount(res.data.data);
            if (hp.canAccessDashboard(user.role)) this.props.getDashboardData();
            this.setState({ loading: false });
          } else {
            this.props.signOut();
          }
        })
        .catch((err) => {
          this.props.signOut();
        });
    } else {
      if (hp.canAccessDashboard(this.props.auth.user.role)) {
        this.props.getDashboardData();
      }
      this.setState({ loading: false });
    }
  }

  render() {
    const { loading } = this.state;
    const {
      auth: { authenticated },
      utils: { lang, pageTitle },
    } = this.props;
    return (
      <DocumentTitle title={pageTitle}>
        <IntlProvider locale={lang} messages={localeIntl[lang]}>
          {loading === true ? (
            <LoadingApp />
          ) : authenticated ? (
            <AppContainer />
          ) : (
            <AuthContainer />
          )}
        </IntlProvider>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  utils: state.utils,
});

const mapDispatchToProps = (dispatch) => ({
  setAccount: (data) => {
    dispatch(authAct.setAccount(data));
  },
  getDashboardData: () => dispatch(getDashBoardData()),
  signOut: () => {
    dispatch(authAct.signOut());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
