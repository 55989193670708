import React from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from 'react-intl';
import { Layout, notification, Select } from 'antd';
import { ConnectedRouter } from 'connected-react-router';
import { history } from "../store/_store";
import { cf, cw } from '../utils';

import * as authAct from "../store/auth/_authActions";
import * as utilsAct from "../store/utils/_utilsActions";

import PushSider from '../layouts/Sider/Sider';
import EsokoHeader from '../components/Header/Header';

// import NoMatch from "./NoMatch/NoMatch";
import HomeScreen from "./HomeScreen/HomeScreen";
import Organisation from "./Organisation/Organisation";
import Settings from "./Settings/Settings";
import Administrators from './Administrators/Administrators';

class AppContainer extends React.PureComponent {

  state = {
    redirect: false, submitting: false
  }

  sendVerificationEmail() {
    const { auth: { user: { email, user_id }, account }, utils } = this.props;
    axios({
      method: 'POST',
      url: `${cf.SSO_URL}/verifications`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: { email, language: utils.lang || 'en', userId: user_id, accountId: account.id }
    }).then((response) => {
      this.setState({ submitting: false });
      const res = response.data;
      if (res.statusCode === 200) {
        notification.success({
          message: this.props.intl.formatMessage(cw.Success),
          description: this.props.intl.formatMessage({ ...cw.SendVerificationMailSuccess, values: { email } })
        });
      } else {
        notification.error({
          message: this.props.intl.formatMessage(cw.SavingFailed),
          description: this.props.intl.formatMessage(cw.SendVerificationMailFail)
        });
      }
    }).catch((err) => {
      this.setState({ submitting: false });
      notification.error({
        message: this.props.intl.formatMessage(cw.SavingFailed),
        description: this.props.intl.formatMessage(cw.SendVerificationMailFail)
      });
    });
  }

  render() {
    const { utils, auth: { user, account } } = this.props;
    const { location: { pathname } } = history;

    if (pathname === '/') {
      this.setState({ redirect: true });
    }

    return (
      <Router>
        <ConnectedRouter history={history}>

          <Layout style={{ minHeight: '100vh' }}>
            <EsokoHeader account={account} user={user} signOut={this.props.signOut} setAccount={e => this.props.setAccount(e)} app="account" />
            <Layout>
              <PushSider history={history} {...this.props} />
              <Layout.Content style={{ marginLeft: utils.siderWidth }}>
                <div style={{ background: '#fff', minHeight: 700, padding: '2rem 5% 0 5rem' }}>

                  {user.status[0].toLowerCase() === 'p' && (
                    <div>
                      <div className="alert-danger">
                        <span><FormattedMessage id="Header.ClickToVerify" defaultMessage="Click on the link in your email to verify and activate this account" />.</span> {' '}
                        <span className="link" onClick={() => this.sendVerificationEmail()}><FormattedMessage id="Header.ResendVerification" defaultMessage="Resend verification email" />.</span>
                      </div>
                    </div>
                  )}

                  <div style={{ minHeight: '80vh' }}>
                    <Switch>
                      <Route excat path={`/${utils.lang}/home`} render={() => <HomeScreen {...this.props} />} />
                      <Route excat path={`/${utils.lang}/organisation`} render={() => <Organisation {...this.props} />} />
                      <Route excat path={`/${utils.lang}/administrators`} render={() => <Administrators {...this.props} />} />
                      <Route excat path={`/${utils.lang}/settings`} render={() => <Settings {...this.props} />} />

                      {/* <Route render={() => <NoMatch {...this.props} />} /> */}
                      <Redirect to={`/${utils.lang}/home`} />
                    </Switch>
                  </div>

                  <div style={{ textAlign: 'right' }}>
                    <Select className="switch__lang" defaultValue={this.props.utils.lang} size="small" onChange={(lang) => { this.props.setLang(lang); }}>
                      <Select.Option value="en">English</Select.Option>
                      <Select.Option value="fr">Français</Select.Option>
                    </Select>
                  </div>
                </div>
              </Layout.Content>
            </Layout>
          </Layout>

        </ConnectedRouter>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  utils: state.utils
});

const mapDispatchToProps = (dispatch) => ({
  setPageTitle: (int, title) => {
    dispatch(utilsAct.setPageTitle(int, title))
  },
  signOut: () => {
    dispatch(authAct.signOut())
  },
  setSiderWidth: (width) => {
    dispatch(utilsAct.setSiderWidth(width))
  },
  setLang: (lang) => {
    dispatch(utilsAct.setLang(lang))
  },
  setAccount: (data) => {
    dispatch(authAct.setAccount(data));
  }
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AppContainer));