import React from 'react';
import { SET_PAGE_TITLE, SET_PAGE_SUB_TITLE, SET_SITE_LANG, SET_SIDER_WIDTH } from '../_types';
import { getCookie } from '../../utils/helpers';
import { FormattedMessage } from 'react-intl';

const initialState = {
    pageTitle: 'Esoko Account',
    pageSubTitle: '',
    lang: getCookie('site_lang') || 'en',
    siderWidth: 256,
    industries: [
        <FormattedMessage id="Category.Agriculture" defaultMessage="Agriculture" />,
        <FormattedMessage id="Category.ArtsEntertainmentRecreation" defaultMessage="Arts, Entertainment, and Recreation" />,
        <FormattedMessage id="Category.Automobile" defaultMessage="Automobile" />,
        <FormattedMessage id="Category.Aviation" defaultMessage="Aviation" />,
        <FormattedMessage id="Category.Construction" defaultMessage="Construction" />,
        <FormattedMessage id="Category.Ecommerce" defaultMessage="Ecommerce" />,
        <FormattedMessage id="Category.Education" defaultMessage="Education" />,
        <FormattedMessage id="Category.Finance" defaultMessage="Finance" />,
        <FormattedMessage id="Category.Gaming" defaultMessage="Gaming" />,
        <FormattedMessage id="Category.Government" defaultMessage="Government" />,
        <FormattedMessage id="Category.HealthServices" defaultMessage="Health Services" />,
        <FormattedMessage id="Category.Hospitality" defaultMessage="Hospitality" />,
        <FormattedMessage id="Category.InformationTechnology" defaultMessage="Information Technology" />,
        <FormattedMessage id="Category.Insurance" defaultMessage="Insurance" />,
        <FormattedMessage id="Category.Manufacturing" defaultMessage="Manufacturing" />,
        <FormattedMessage id="Category.Media" defaultMessage="Media" />,
        <FormattedMessage id="Category.NaturalResourcesMining" defaultMessage="Natural Resources and Mining" />,
        <FormattedMessage id="Category.Non-ProfitOrganization" defaultMessage="Non-Profit Organization" />,
        <FormattedMessage id="Category.OilGas" defaultMessage="Oil & Gas" />,
        <FormattedMessage id="Category.Pharmaceutical" defaultMessage="Pharmaceutical" />,
        <FormattedMessage id="Category.ProfessionalBusinessServices" defaultMessage="Professional and Business Services" />,
        <FormattedMessage id="Category.RealEstate" defaultMessage="Real Estate" />,
    ]
};

const utilsReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;

        case SET_PAGE_TITLE:
            return {
                ...state,
                pageTitle: action.title
            }

        case SET_PAGE_SUB_TITLE:
            return {
                ...state,
                pageSubTitle: action.title
            };
        case SET_SITE_LANG:
            return {
                ...state,
                lang: action.lang
            };
        case SET_SIDER_WIDTH:
            return {
                ...state,
                siderWidth: action.width
            };
    }
};


export default utilsReducer;