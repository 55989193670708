import { Modal, Form, Button, Input, notification } from 'antd';
import React from 'react';
import axios from 'axios';
import { FormattedMessage, injectIntl } from 'react-intl';
import { hp, cf, cw } from '../../utils';

const AdministratorsForm = props => {
    const { intl, visible, onClose, onFinish, form: { getFieldDecorator, getFieldValue, validateFields }, auth: { account }, } = props;

    const [submitting, setSubmitting] = React.useState(false);

    const submit = (e) => {
        validateFields(['inviteeName', 'email'], (err, data) => {
            if (!err) {
                setSubmitting(true);
                data['email'] = data['email'].trim().toLowerCase();
                data['language'] = hp.getCookie('site_lang');
                axios({
                    method: 'POST',
                    url: `${cf.SSO_URL}/accounts/${account.id}/invitations`,
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'x-access-token': hp.getCookie('user_token')
                    },
                    data
                }).then((response) => {
                    setSubmitting(false);
                    const res = response.data;
                    if (res.statusCode === 200) {
                        onFinish();
                        onClose();
                        notification.success({ message: intl.formatMessage(cw['Success']), description: intl.formatMessage(cw['Success']) });
                    } else {
                        notification.error({ message: intl.formatMessage(cw['Success']), description: intl.formatMessage(cw['Invitation.Send.Failed']) });
                    }
                }).catch((err) => {
                    const res = JSON.parse((err.request || '').response);
                    setSubmitting(false);
                    notification.error({ message: intl.formatMessage(cw['Failed']), description: res.message });
                    // notification.error({ message: intl.formatMessage(cw['Failed']), description: intl.formatMessage(cw['Invitation.Send.Failed']) });
                });
            }
        });
    }


    return (
        <React.Fragment>
            <Modal title={<FormattedMessage id="Administrators.Form.Title" defaultMessage="Add Administrator" />} visible={visible} className="admin__modal"
                onCancel={() => onClose()} destroyOnClose={true} maskClosable={false}
                footer={[
                    <Button type="link" account-id="admin-invite-button" onClick={() => onClose()} sso-id="administrators-invite-cancel">
                        <FormattedMessage id="Button.Cancel" defaultMessage="Cancel" />
                    </Button>,
                    <Button
                        type="primary" account-id="admin-invite-button" className="submitBtn" loading={submitting}
                        disabled={!getFieldValue('fullanme') && !getFieldValue('email')}
                        onClick={submit} sso-id="administrators-invite-send"
                    >
                        <FormattedMessage id="Administrators.Form.Button" defaultMessage="Send Invitation" />
                    </Button>,
                ]}
            >
                <div style={{ color: '#AD0000' }}>
                    <FormattedMessage id="Administrators.Form.Note" defaultMessage="Note: This administrator will have full acess to your organisation's account" />
                </div>
                <div style={{ marginTop: 20 }}>
                    <Form.Item required={true} colon={false} label={intl.formatMessage(cw['Administrators.Form.Fullname'])}>
                        {getFieldDecorator('inviteeName', {
                            rules: [
                                { required: true, message: intl.formatMessage(cw['Administrators.Form.Fullname.Required']) },
                                { min: 2, message: intl.formatMessage(cw['Administrators.Form.Fullname.Min2']) },
                                { max: 50, message: intl.formatMessage(cw['Administrators.Form.Fullname.Max50']) }
                            ]
                        })(
                            <Input autoComplete="off" disabled={submitting} sso-id="administrators-invite-fullname" placeholder={intl.formatMessage(cw['Administrators.Form.Fullname.Placeholder'])} account-id="admin-invite-form-fullname" />
                        )}
                    </Form.Item>
                    <Form.Item required={true} colon={false} label={intl.formatMessage(cw['Label.EmailAddress'])}>
                        {getFieldDecorator('email', {
                            rules: [
                                { required: true, message: intl.formatMessage(cw['Administrators.Form.Email.Required']) },
                                { type: 'email', message: intl.formatMessage(cw['Administrators.Form.Email.Invalid']) }
                            ]
                        })(
                            <Input autoComplete="off" disabled={submitting} sso-id="administrators-invite-email" placeholder={intl.formatMessage(cw['Administrators.Form.Email.Placeholder'])} account-id="admin-invite-form-email" />
                        )}
                    </Form.Item>
                </div>
            </Modal>
        </React.Fragment>
    );
}


export default injectIntl(Form.create()(AdministratorsForm));