import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import { FormattedMessage } from "react-intl";
import { cw } from "../../utils";

import AuthHeader from './Header';

class SignIn extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            visaMomoModalVisible: false, bankModalVisible: false,
            currency: '', modalFooterImages: []
        };
    }

    _signin = e => {
        e.preventDefault();
        const { form: { validateFields }, signIn, intl, utils: { lang } } = this.props;
        validateFields((err, vals) => {
            if (!err) {
                signIn(intl, lang, { email: vals.email.trim(), password: vals.password });
            }
        });
    };

    render() {
        const { form: { getFieldDecorator, getFieldValue }, utils, auth: { signingIn }, intl } = this.props;
        return (
            <React.Fragment>
                <Form id="esoko__signin" autoComplete="off" hideRequiredMark onSubmit={this._signin}>
                    <AuthHeader screen="signin" />

                    <Form.Item required={true} colon={false} label={intl.formatMessage(cw['auth_form_username'])}>
                        {getFieldDecorator('email', {
                            rules: [
                                { required: true, message: intl.formatMessage(cw.formErrorMsgInputUserName) }
                            ]
                        })(
                            <Input autoFocus autoComplete="off" size="large" placeholder="you@domain.com" />
                        )}
                    </Form.Item>
                    <Form.Item required={true} colon={false} label={intl.formatMessage(cw['auth_form_password'])}>
                        {getFieldDecorator('password', {
                            rules: [
                                { required: true, message: intl.formatMessage(cw.formErrorMsgInputPassword) }
                            ]
                        })(
                            <Input.Password autoComplete="off" size="large" type="password" placeholder="Enter your password" />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button className="submitBtn" block size="large" type="primary" htmlType="submit" disabled={!getFieldValue('email') && !getFieldValue('password')} loading={signingIn}>
                            <FormattedMessage id="auth_signin_button" defaultMessage="Sign in" />
                        </Button>
                    </Form.Item>

                    <div className="formLinks">
                        <Link to={`/${utils.lang || 'en'}/reset`} className="auth_form_links_forgot">
                            <FormattedMessage id="auth_signin_forgot_link" defaultMessage="Forgot password?" />
                        </Link>
                        <p>
                            <span className="noAccountText"><FormattedMessage id="auth_signin_not_account" defaultMessage="Don't have an account?" /></span>
                            <Link to={`/${utils.lang || 'en'}/signup`}><FormattedMessage id="auth_signin_create_account_link" defaultMessage="Create an account" /></Link>
                        </p>
                    </div>
                </Form>
            </React.Fragment>
        );
    }
}

const SignInForm = Form.create()(SignIn);
export default SignInForm;