// import { notification } from 'antd';
// import axios from 'axios';
// import base64 from 'base-64';

import { SET_USER, SET_ACCOUNT } from "../_types";
import { cf, hp } from "../../utils";
import axios from "axios";

export const setLoginStorage = (user, account) => {
  const oldUser = hp.getCookie("user") || {};
  let ssoUser = {
    user_id: user.userId || user.id,
    first_name: user.firstName,
    last_name: user.lastName,
    email: user.email,
    msisdn: user.msisdn,
    account_id: account.accountId,
    role: user.role,
    user_preferences: user.preferences || {},
    status: user.status[0],
    signup_country:
      user.country && user.country !== "undefined" ? user.country : "Ghana",
    profile_image:
      user.profileImage || user.profileImageUrl || oldUser.profile_image,
  };
  hp.setCookie("user", JSON.stringify(ssoUser), 7);
  hp.setCookie("account", JSON.stringify(account), 7);
  hp.setCookie("user_id", ssoUser.user_id);
  hp.setCookie("user_name", ssoUser.first_name);
  return ssoUser;
};

export function signIn(intl, lang, data) {}

export function signUp(intl, lang, data) {}

export function setUser(user) {
  let account = JSON.parse(hp.getCookie("active_account"));
  let data = setLoginStorage(user, account);
  return (dispatch) => {
    dispatch({ type: SET_USER, data });
  };
}

export function setAccount(data) {
  return (dispatch) => {
    dispatch({ type: SET_ACCOUNT, data });
  };
}

export function signOut() {
  const lang = window.location.pathname.split("/")[1] || "en";
  window.location.assign(
    `${process.env.REACT_APP_SSO_WEB_URL}/${lang}/signout?continue=${window.location.href}`
  );
  // return dispatch => {
  //     hp.deleteCookie('user');
  //     hp.deleteCookie('user_token');
  //     hp.deleteCookie('user_id');
  //     hp.deleteCookie('account');
  //     dispatch(window.location.assign(`/en/signin`));
  //     dispatch({ type: SIGNOUT_SUCCESS });
  // }
}

export function UpdateUserRole(userId, role) {
  return axios({
    method: "PUT",
    url: `${cf.SSO_URL}/user-accounts`,
    data: { userId, role },
    headers: { "x-access-token": hp.getCookie("user_token") },
  })
    .then((response) => {
      const res = response.data;
      if (res.statusCode === 200) {
        return Promise.resolve(res);
      } else {
        throw Error("Failed to update role");
      }
    })
    .catch((e) => {
      throw e;
    });
}
