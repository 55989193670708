import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';
import { Col, Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import { hp } from '../../utils';
import { apps, dashBoardStats, dashBoardRecent } from './utils';
import './HomeScreen.scss';
import DefaultLogo from '../../assets/img/company.svg';
import { DashboardStats, DashboardTable } from './components/DashboardElements';
import { getDashBoardData } from '../../store/dashboard';

export class HomeScreen extends Component {
  state = {};
  services = [];

  constructor() {
    super();
  }

  componentWillMount = () => {
    const { setPageTitle } = this.props;
    setPageTitle('Esoko');
  };
  componentDidMount = () => {
    this.services = apps.filter(
      app => !app.access || hp.hasP(app.access, this.props.auth.user.role)
    );
  };

  render() {
    const {
      dashboard,
      auth: { user, account },
    } = this.props;

    return (
      <div style={{ width: '100%' }}>
        <div id='accounts__home' className='animated fadeIn faster'>
          <section style={{ textAlign: 'center', marginTop: '10%' }}>
            {account.logoUrl && (
              <div className='' style={{ margin: '0 auto' }}>
                <img
                  src={account.logoUrl}
                  alt={'Organisation'}
                  style={{ height: 100 }}
                />
              </div>
            )}
            {account.logoUrl === null && (
              <div className='defaultLogo' style={{ margin: '0 auto' }}>
                <img src={DefaultLogo} alt={'Organisation'} />
              </div>
            )}

            <div className='h1'>
              <FormattedMessage id='Welcome' defaultMessage='Welcome' />,{' '}
              {user.first_name}!
            </div>
            <div className='h2'>
              <FormattedMessage
                id='Welcome.Text2'
                defaultMessage='Where would you like to go?'
              />
            </div>
          </section>
          <section className='apps'>
            <Row type={'flex'} justify={'center'} gutter={15}>
              {this.services.map(app => {
                const serviceLength = this.services.length;
                if (serviceLength == 5 && app.name == 'People') return null;

                return (
                  <Col
                    lg={serviceLength >= 4 ? 6 : 8}
                    md={12}
                    sm={24}
                    xs={24}
                    key={app.name}
                  >
                    <div
                      className='app'
                      esoko-account-id={app.dfs}
                      onClick={() => {
                        app.url && window.location.assign(app.url);
                      }}
                    >
                      <Row gutter={20}>
                        {/*<Col lg={5} sm={24} className="icon one">*/}
                        {/*  <img src={app.icon} alt={app.name} />*/}
                        {/*</Col>*/}
                        <Col lg={24}>
                          <div className='h1'>{app.name}</div>
                          <div className='h2'>{app.description}</div>
                        </Col>
                        {/*<Col lg={5} sm={24} className="icon two">*/}
                        {/*  <img src={app.icon} alt={app.name} />*/}
                        {/*</Col>*/}
                      </Row>
                    </div>
                  </Col>
                );
              })}
            </Row>
            {hp.canAccessDashboard(user.role) && (
              <div style={{ marginTop: 20, marginBottom: '100px' }}>
                <Row gutter={15}>
                  {dashBoardStats.map(service => (
                    <Col lg={8} md={12} sm={24} xs={24} key={service.dataId}>
                      <DashboardStats
                        isLoading={dashboard.data[service.dataId].isLoading}
                        data={dashboard.data[service.dataId].results}
                        service={service}
                      />
                    </Col>
                  ))}
                </Row>
                <Row align={'stretch'} style={{ marginTop: 20 }} gutter={15}>
                  {dashBoardRecent.map(service => (
                    <Col lg={8} md={12} sm={24} xs={24} key={service.dataId}>
                      <DashboardTable
                        isLoading={dashboard.data[service.dataId].isLoading}
                        data={dashboard.data[service.dataId].results || []}
                        service={service}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            )}
          </section>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  dashboard: state.dashboard,
});

const mapDispatchToProps = dispatch => ({
  getDashboardData: () => dispatch(getDashBoardData()),
});

const Home = Form.create()(HomeScreen);
export default connect(mapStateToProps, mapDispatchToProps)(Home);
