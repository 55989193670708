import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Form, Button, Input, notification, Select, message } from "antd";
import { CopyFill } from "@ant-design/icons";

import { Row, Col } from "rsuite";
import { FormattedMessage, injectIntl } from "react-intl";
import { hp, cw, cf } from "../../utils";
import * as authAct from "../../store/auth/_authActions";
// import * as $ from 'jquery';

import "./Organisation.scss";
import DefaultLogo from "../../assets/img/company.svg";

// const fieldsNames = {
//   industry: 'Industry Type',
// };

export class OrganisationScreen extends Component {
  state = {
    formLogo: false,
    formName: false,
    formIndustry: false,
    submitting: false,
    file: null,
    logo: null,
    showApiKey: false,
  };

  componentWillMount = () => {
    const { setPageTitle } = this.props;
    setPageTitle(`Organisation - Esoko`);
    // setPageTitle(`${account.name} - Esoko`);
  };

  componentDidMount = () => {};

  forms = (name, value) => {
    this.setState({ ["form" + name]: value });
  };

  validateShortName = (e) => {
    // $('#shortName').val(e.target.value.trim());
  };

  handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        if (file.type.includes("image")) {
          this.setState({ file, logo: reader.result });
        } else {
          this.setState({ file: null, logo: null });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  save = (name) => {
    const {
      auth: { account },
      form: { validateFields },
    } = this.props;
    validateFields((err, v) => {
      if (!err) {
        this.setState({ submitting: true });
        var data = {
          description: v["description"] || undefined,
          name: v["name"] || account.name || undefined,
          shortName:
            v["shortName"].trim() || account.shortName.trim() || undefined,
          industry: v["industry"] || account.industry || undefined,
        };
        axios({
          method: "PUT",
          url: `${cf.SSO_URL}/accounts/${account.id}`,
          data,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": hp.getCookie("user_token"),
          },
        })
          .then((response) => {
            this.setState({ submitting: false });
            const res = response.data;
            if (res.statusCode === 200) {
              this.forms(name, false);
              hp.setCookie("account", JSON.stringify(res.data));
              hp.setCookie("active_account", JSON.stringify(res.data));
              this.props.setAccount(res.data);
              notification.success({
                message: this.props.intl.formatMessage(cw.Success),
                description: this.props.intl.formatMessage({
                  ...cw.SaveOrganisationSuccess,
                  values: { name },
                }),
              });
            } else {
              notification.error({
                message: this.props.intl.formatMessage(cw.SavingFailed),
                description: this.props.intl.formatMessage({
                  ...cw.SaveOrganisationFail,
                  values: { name },
                }),
              });
            }
          })
          .catch((err) => {
            this.setState({ submitting: false });
            notification.error({
              message: this.props.intl.formatMessage(cw.SavingFailed),
              description: this.props.intl.formatMessage({
                ...cw.SaveOrganisationFail,
                values: { name },
              }),
            });
          });
      }
    });
  };

  upload = () => {
    const { file } = this.state;
    const {
      auth: { account },
    } = this.props;
    if (file) {
      this.setState({ submitting: true });
      let formData = new FormData();
      formData.append("file", file || null);
      formData.append("fileType", "accountImage");
      axios({
        method: "POST",
        url: `${cf.SSO_URL}/accounts/${account.id}/files`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": hp.getCookie("user_token"),
        },
        data: formData,
      })
        .then((response) => {
          this.setState({ submitting: false });
          const res = response.data;
          if (res.statusCode === 200) {
            this.setState({ file: null, logo: null });
            hp.setCookie("account", JSON.stringify(res.data));
            hp.setCookie("active_account", JSON.stringify(res.data));
            this.props.setAccount(res.data);
            notification.success({
              message: this.props.intl.formatMessage(cw.Success),
              description: this.props.intl.formatMessage(
                cw["SaveOrganisationLogoSuccess"]
              ),
            });
          } else {
            notification.error({
              message: this.props.intl.formatMessage(cw.SavingFailed),
              description: this.props.intl.formatMessage(
                cw["SaveOrganisationLogoFail"]
              ),
            });
          }
        })
        .catch((err) => {
          this.setState({ submitting: false });
          notification.error({
            message: this.props.intl.formatMessage(cw.SavingFailed),
            description: this.props.intl.formatMessage(
              cw["SaveOrganisationLogoFail"]
            ),
          });
        });
    }
  };

  render() {
    const {
      auth: { account },
      form: { getFieldDecorator },
      intl,
      utils,
    } = this.props;
    const { formLogo, formName, formIndustry, submitting, logo } = this.state;

    return (
      <React.Fragment>
        <div id="accounts__organisation" className="animated fadeIn faster">
          <div className="top">
            <div className="h1">
              <FormattedMessage
                id="Organisation.Title"
                defaultMessage="Organisation"
              />
            </div>
            <div className="h2">
              <FormattedMessage
                id="Organisation.Title.Description"
                defaultMessage="Basic info, such as your name and photo, that you use on Google services"
              />
            </div>
          </div>

          <div className="cards">
            {/* Logo */}
            <div className={`card ${formLogo ? "edit" : ""}`}>
              <div className="title">
                <div className="h1">
                  <FormattedMessage
                    id="Organisation.Logo"
                    defaultMessage="Organisation Logo"
                  />
                </div>
                <div className="h2">
                  <FormattedMessage
                    id="Organisation.Logo.Description"
                    defaultMessage="Provide a logo for your organization."
                  />
                </div>
                <p>&nbsp;</p>
                <Row>
                  <Col lg={16}>
                    {logo === null && account.logoUrl === null && (
                      <div className={`defaultLogo`}>
                        <img src={DefaultLogo} alt={account.name} />
                      </div>
                    )}
                    {logo === null && account.logoUrl && (
                      <div>
                        <img
                          src={account.logoUrl}
                          alt={account.name}
                          style={{ maxWidth: 130 }}
                        />
                      </div>
                    )}
                    {logo !== null && (
                      <img
                        src={logo}
                        alt={account.name}
                        style={{ maxWidth: 130 }}
                      />
                    )}
                  </Col>
                  <Col lg={8} style={{ textAlign: "right" }}>
                    <p>&nbsp;</p>
                    <input
                      data-insyt-id="edit-profile-input"
                      onChange={this.handleImageChange}
                      accept="image/*"
                      ref="uploader"
                      type="file"
                      name="image"
                      style={{ display: "none" }}
                    />
                    {logo === null && (
                      <Button
                        type="ghost"
                        disabled={submitting}
                        esoko-account-id="organisation_edit_logo"
                        onClick={() => this.refs.uploader.click()}
                      >
                        <FormattedMessage
                          id="Button.Upload"
                          defaultMessage="Upload"
                        />
                      </Button>
                    )}
                    {logo !== null && (
                      <span>
                        <Button
                          type="ghost"
                          disabled={submitting}
                          esoko-account-id="organisation_cancel_name"
                          onClick={() =>
                            this.setState({ file: null, logo: null })
                          }
                        >
                          <FormattedMessage
                            id="Button.Cancel"
                            defaultMessage="Cancel"
                          />
                        </Button>{" "}
                        <Button
                          type="primary"
                          loading={submitting}
                          esoko-account-id="organisation_save_logo"
                          onClick={() => this.upload()}
                        >
                          <FormattedMessage
                            id="Button.Save"
                            defaultMessage="Save"
                          />
                        </Button>
                      </span>
                    )}
                  </Col>
                </Row>
              </div>
            </div>

            {/* Name */}
            {(account.industry || account.shortName || account.short_name) && (
              <div className={`card ${formName ? "edit" : ""}`}>
                <div className="title">
                  <div className="h1">
                    <FormattedMessage
                      id="Organisation.Name.Title"
                      defaultMessage="Organisation Name"
                    />
                  </div>
                  <div className="h2">
                    <FormattedMessage
                      id="Organisation.Name.Description"
                      defaultMessage="Tell us the name of your organization."
                    />
                  </div>
                  <p>&nbsp;</p>
                  {formName === false && (
                    <Row>
                      <Col lg={16}>
                        <p>
                          <div className="label">
                            <FormattedMessage
                              id="Label.FullName"
                              defaultMessage="Full Name"
                            />
                          </div>
                          <div className="value">{account.name}</div>
                        </p>
                        <p>
                          <div className="label">
                            <FormattedMessage
                              id="Label.ShortName"
                              defaultMessage="Short Name"
                            />
                          </div>
                          <div className="value">
                            {account.shortName || account.short_name}
                          </div>
                        </p>
                        <p>
                          <div className="label">
                            <FormattedMessage
                              id="Label.Description"
                              defaultMessage="Description"
                            />
                          </div>
                          <div className="value">{account.description}</div>
                        </p>
                      </Col>
                      <Col lg={8} style={{ textAlign: "right" }}>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <Button
                          type="ghost"
                          disabled={submitting}
                          esoko-account-id="organisation_edit_name"
                          onClick={() => this.forms("Name", true)}
                        >
                          <FormattedMessage
                            id="Button.Change"
                            defaultMessage="Change"
                          />
                        </Button>
                      </Col>
                    </Row>
                  )}
                  {formName === true && (
                    <Form>
                      <Row>
                        <Col lg={24}>
                          <p>
                            <div className="label">
                              <FormattedMessage
                                id="Label.FullName"
                                defaultMessage="Full Name"
                              />
                            </div>
                            <Form.Item
                              required={true}
                              colon={false}
                              label={null}
                            >
                              {getFieldDecorator("name", {
                                rules: [
                                  {
                                    required: true,
                                    message: intl.formatMessage(
                                      cw.formErrorMsgInputOrgName
                                    ),
                                  },
                                ],
                                initialValue: account.name,
                              })(<Input autoComplete="off" placeholder="" />)}
                            </Form.Item>
                          </p>
                          <p>
                            <div className="label">
                              <FormattedMessage
                                id="Label.ShortName"
                                defaultMessage="Short Name"
                              />
                            </div>
                            <Form.Item
                              required={true}
                              colon={false}
                              label={null}
                            >
                              {getFieldDecorator("shortName", {
                                rules: [
                                  // { required: true, message: intl.formatMessage(cw.formErrorMsgInputOrgShortName) },
                                  {
                                    min: 2,
                                    message:
                                      "Must be at least 2 characters long",
                                  },
                                  {
                                    max: 10,
                                    message:
                                      "Can not be more than 10 characters long",
                                  },
                                ],
                                initialValue:
                                  account.shortName || account.short_name,
                              })(
                                <Input
                                  autoComplete="off"
                                  placeholder=""
                                  maxLength={10}
                                  onChange={this.validateShortName}
                                />
                              )}
                            </Form.Item>
                          </p>
                          <p>
                            <div className="label">
                              <FormattedMessage
                                id="Label.Description"
                                defaultMessage="Description"
                              />
                            </div>
                            <Form.Item
                              required={true}
                              colon={false}
                              label={null}
                            >
                              {getFieldDecorator("description", {
                                rules: [
                                  // { required: true, message: intl.formatMessage(cw.formErrorMsgInputOrgShortName) },
                                ],
                                initialValue: account.description,
                              })(
                                <Input.TextArea
                                  rows={5}
                                  autoComplete="off"
                                  placeholder=""
                                />
                              )}
                            </Form.Item>
                          </p>
                        </Col>
                        <Col lg={24} style={{ textAlign: "right" }}>
                          <Button
                            type="ghost"
                            disabled={submitting}
                            esoko-account-id="organisation_cancel_name"
                            onClick={() => this.forms("Name", false)}
                          >
                            <FormattedMessage
                              id="Button.Cancel"
                              defaultMessage="Cancel"
                            />
                          </Button>{" "}
                          <Button
                            type="primary"
                            loading={submitting}
                            esoko-account-id="organisation_save_name"
                            onClick={() => this.save("Name")}
                          >
                            <FormattedMessage
                              id="Button.Save"
                              defaultMessage="Save"
                            />
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </div>
              </div>
            )}

            {/* Industry */}
            <div className={`card ${formIndustry ? "edit" : ""}`}>
              <div className="title">
                <div className="h1">
                  <FormattedMessage
                    id="Organisation.Industry.Title"
                    defaultMessage="Industry Type"
                  />
                </div>
                <div className="h2">
                  <FormattedMessage
                    id="Organisation.Industry.Description"
                    defaultMessage="What industry sector is your business operating in?"
                  />
                </div>
                <p>&nbsp;</p>
                {formIndustry === false && (
                  <Row>
                    <Col lg={16}>
                      <div className="value">{account.industry}</div>
                    </Col>
                    <Col lg={8} style={{ textAlign: "right" }}>
                      <Button
                        type="ghost"
                        disabled={submitting}
                        esoko-account-id="organisation_edit_industry"
                        onClick={() => this.forms("Industry", true)}
                      >
                        <FormattedMessage
                          id="Button.Change"
                          defaultMessage="Change"
                        />
                      </Button>
                    </Col>
                  </Row>
                )}
                {formIndustry === true && (
                  <Row>
                    <Col lg={12}>
                      <Form.Item colon={false} label={null}>
                        {getFieldDecorator("industry", {
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage(cw.required_industry),
                            },
                          ],
                          initialValue: account.industry,
                        })(
                          <Select
                            showSearch={
                              utils.industries.length > 5 ? true : false
                            }
                            style={{ width: "100%" }}
                          >
                            {utils.industries.map((industry) => (
                              <Select.Option key={industry} value={industry}>
                                {industry}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col lg={24} style={{ textAlign: "right" }}>
                      <Button
                        type="ghost"
                        disabled={submitting}
                        esoko-account-id="organisation_cancel_industry"
                        onClick={() => this.forms("Industry", false)}
                      >
                        <FormattedMessage
                          id="Button.Change"
                          defaultMessage="Change"
                        />
                      </Button>{" "}
                      <Button
                        type="primary"
                        loading={submitting}
                        esoko-account-id="organisation_save_industry"
                        onClick={() => this.save("Industry")}
                      >
                        <FormattedMessage
                          id="Button.Save"
                          defaultMessage="Save"
                        />
                      </Button>
                    </Col>
                  </Row>
                )}
              </div>
            </div>

            {/* Api Key */}
            <div className={`card ${formIndustry ? "edit" : ""}`}>
              <div className="title">
                <div className="h1">
                  <FormattedMessage
                    id="Organisation.apiKey.Title"
                    defaultMessage="Api Key"
                  />
                </div>
                <div className="h2">
                  <FormattedMessage
                    id="Dev.Description"
                    defaultMessage="Power up your applications."
                  />
                </div>
                <div className="ApiKey-Content">
                  <Input
                    id="api_key"
                    readOnly
                    type="text"
                    value={
                      this.state.showApiKey
                        ? account.apiKey
                        : "●●●●●●●●●●●●●●●●●"
                    }
                  />
                  <Button
                    onClick={(e) => {
                      if (this.state.showApiKey) {
                        const copyText = document.getElementById("api_key");
                        copyText.select();
                        document.execCommand("copy");
                        message.success(`API Key copied successfully`);
                        return;
                      }
                      this.setState({ showApiKey: true });
                    }}
                  >
                    {this.state.showApiKey ? (
                      <FormattedMessage
                        id="Button.Copy"
                        defaultMessage="Copy"
                      />
                    ) : (
                      <FormattedMessage
                        id="Button.Show"
                        defaultMessage="Show"
                      />
                    )}
                  </Button>
                  <div className="h2">
                    <FormattedMessage
                      id="Organisation.Industry.Description"
                      defaultMessage="To view all APIs available for SMS and Voice integrations, visit Insyt "
                    />
                    <a target="_blank" href="https://www.insyt.esoko.com/developers/">
                      <FormattedMessage
                        id="Organisation.Industry.Description"
                        defaultMessage="Developers Documentation."
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setAccount: (data) => {
    dispatch(authAct.setAccount(data));
  },
});

const Organisation = Form.create()(OrganisationScreen);
export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(Organisation)
);
