import React, { Component } from 'react';
import axios from "axios";
import { connect } from "react-redux";
import { Form, Button, Input, notification } from "antd";
import { Row, Col } from "rsuite";
import { FormattedMessage, injectIntl } from "react-intl";
import { hp, cw, cf } from "../../utils";
import * as authAct from "../../store/auth/_authActions";

import './Settings.scss';
import DefaultLogo from '../../assets/img/profile.svg';

export class ProfileScreen extends Component {

  state = {
    formProfile: false, formPassword: false, submitting: false,
    file: null, profile_image: null
  }

  componentWillMount = () => {
    const { setPageTitle } = this.props;
    setPageTitle(`Settings - Esoko`);
    // setPageTitle(`${user.first_name} ${user.last_name[0]}. - Esoko`);
  }

  componentDidMount = () => {

  }

  forms = (name, value) => {
    this.setState({ ['form' + name]: value });
  }

  save = (name) => {
    const { auth: { user }, form: { validateFields } } = this.props;
    validateFields((err, v) => {
      if (!err) {
        this.setState({ submitting: true });
        axios({
          method: 'PUT',
          url: `${cf.SSO_URL}/users/${user.user_id}`,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': hp.getCookie('user_token')
          },
          data: {
            firstName: v['firstName'] || user.first_name,
            lastName: v['lastName'] || user.last_name,
            email: v['email'] || user.email,
            msisdn: v['msisdn'] && v['msisdn'] !== user.msisdn ? '+' + v['msisdn'] : undefined,
            password: v['cpassword'] || undefined
          }
        }).then((response) => {
          this.setState({ submitting: false });
          const res = response.data;
          if (res.statusCode === 200) {
            this.forms(name, false);
            hp.setCookie('user', JSON.stringify(res.data));
            this.props.setUser({
              ...res.data,
              role : user.role
            })
            notification.success({ 
              message: this.props.intl.formatMessage(cw.Success), 
              description: this.props.intl.formatMessage({ ...cw.SaveSettingsFailed, values: { name } })
            });
          } else {

          }
        }).catch((err) => {
          this.setState({ submitting: false });
          notification.error({
            message: this.props.intl.formatMessage(cw.SavingFailed),
            description: this.props.intl.formatMessage({ ...cw.SaveSettingsFailed, values: {name} })
          });
        });
      }
    });
  }

  confirmPassword = (rule, value, callback) => {
    if (!value) {
      callback();
    }
    if (value !== this.props.form.getFieldValue('npassword')) {
      callback(this.props.intl.formatMessage(cw.formErrorMsgMismatchPassword));
    }
    callback();
  };

  handleImageChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        if (file.type.includes('image')) {
          this.setState({ file, profile_image: reader.result });
        } else {
          this.setState({ file: null, profile_image: null });
        }
      };
      reader.readAsDataURL(file);
    }
  };
  upload = () => {
    const { file } = this.state;
    const { auth: { user } } = this.props;
    if (file) {
      this.setState({ submitting: true });
      let formData = new FormData();
      formData.append('file', file || null);
      formData.append('fileType', 'profileImage');
      axios({
        method: 'POST',
        url: `${cf.SSO_URL}/users/${user.user_id}/files`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-access-token': hp.getCookie('user_token')
        },
        data: formData
      }).then((response) => {
        this.setState({ submitting: false });
        const res = response.data;
        if (res.statusCode === 200) {
          this.setState({ file: null, profile_image: null });
          setTimeout(() => {
            this.props.setUser({
              ...res.data,
              role : user.role
            })
          }, 100);
          notification.success({
            message: this.props.intl.formatMessage(cw.Success), 
            description: this.props.intl.formatMessage(cw.SaveProfileImageSuccess)
          });
        } else {
          notification.error({
            message: this.props.intl.formatMessage(cw.SavingFailed),
            description: this.props.intl.formatMessage(cw.SaveProfileImageFailed)
          });
        }
      }).catch((err) => {
        this.setState({ submitting: false });
        notification.error({
          message: this.props.intl.formatMessage(cw.SavingFailed),
          description: this.props.intl.formatMessage(cw.SaveProfileImageFailed)
        });
      });
    }

  }

  render() {
    const { auth: { user }, form: { getFieldDecorator }, intl } = this.props;
    const { formProfile, formPassword, submitting, profile_image } = this.state;

    return (
      <React.Fragment>
        <div id="accounts__settings" className="animated fadeIn faster">
          <div className="top">
            <div className="h1"><FormattedMessage id="Settings.Title" defaultMessage="Settings" /></div>
            <div className="h2"><FormattedMessage id="Settings.Title.Description" defaultMessage="Basic info, such as your name and photo, that you see in your user" /></div>
          </div>

          <div className="cards">

            {/* Profile */}
            <div className={`card ${formProfile ? 'edit' : ''}`}>
              <div className="title">
                <div className="h1"><FormattedMessage id="Settings.Profile.Title" defaultMessage="Settings Profile" /></div>
                <div className="h2"><FormattedMessage id="Settings.Profile.Description" defaultMessage="Basic info, such as your name and photo, that you see in your account" /></div>
                <p>&nbsp;</p>
                <Row>
                  <Col lg={16}>
                    {profile_image === null && !user.profile_image && (
                      <img src={DefaultLogo} alt={user.name} />
                    )}
                    {profile_image === null && user.profile_image && (
                      <img src={user.profile_image} alt={user.name} style={{ height: 100 }} />
                    )}
                    {profile_image !== null && (
                      <img src={profile_image} alt={user.name} style={{ height: 100 }} />
                    )}
                  </Col>
                  <Col lg={8} style={{ textAlign: 'right' }}>
                    <p>&nbsp;</p>
                    <input data-insyt-id="edit-profile-input" onChange={this.handleImageChange} accept="image/*" ref="uploader" type="file" name="image" style={{ display: 'none' }} />
                    {profile_image === null && (
                      <Button type="ghost" disabled={submitting} esoko-account-id="settings_edit_avatar" onClick={() => this.refs.uploader.click()}>
                        <FormattedMessage id="Button.Upload" defaultMessage="Upload" />
                      </Button>
                    )}
                    {profile_image !== null && (
                      <span>
                        <Button type="ghost" disabled={submitting} esoko-account-id="organisation_cancel_name" onClick={() => this.setState({ file: null, profile_image: null })}>
                          <FormattedMessage id="Button.Cancel" defaultMessage="Cancel" />
                        </Button> {' '}
                        <Button type="primary" loading={submitting} esoko-account-id="settings_save_avatar" onClick={() => this.upload()}>
                          <FormattedMessage id="Button.Save" defaultMessage="Save" />
                        </Button>
                      </span>
                    )}
                  </Col>
                </Row>
              </div>
              <hr />
              <div className="title">
                {formProfile === false && (
                  <Row>
                    <Col lg={16}>
                      <p>
                        <div className="label"><FormattedMessage id="Label.FullName" defaultMessage="Full Name" /></div>
                        <div className="value">{user.first_name} {user.last_name}</div>
                      </p>
                      <p>
                        <div className="label"><FormattedMessage id="Label.Email" defaultMessage="Email address" /></div>
                        <div className="value">{user.email}</div>
                      </p>
                      <p>
                        <div className="label"><FormattedMessage id="Label.PhoneNumber" defaultMessage="Phone number" /></div>
                        <div className="value">{user.msisdn}</div>
                      </p>
                    </Col>
                    <Col lg={8} style={{ textAlign: 'right' }}>
                      <p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p>
                      <Button type="ghost" disabled={submitting} esoko-account-id="settings_edit_name" onClick={() => this.forms('Profile', true)}>
                        <FormattedMessage id="Button.Change" defaultMessage="Change" />
                      </Button>
                    </Col>
                  </Row>
                )}
                {formProfile === true && (
                  <Row>
                    <Col lg={24}>
                      <Row>
                        <Col lg={12}>
                          <p>
                            <div className="label"><FormattedMessage id="Label.FirstName" defaultMessage="First Password" /></div>
                            <Form.Item required={true} colon={false} label={null}>
                              {getFieldDecorator('firstName', {
                                rules: [
                                  { required: true, message: intl.formatMessage(cw.formErrorMsgInputFirstName) }
                                ],
                                initialValue: user.first_name
                              })(
                                <Input autoComplete="off" disabled={submitting} />
                              )}
                            </Form.Item>
                          </p>
                        </Col>
                        <Col lg={12}>
                          <p>
                            <div className="label"><FormattedMessage id="Label.LastName" defaultMessage="Last Name" /></div>
                            <Form.Item required={true} colon={false} label={null}>
                              {getFieldDecorator('lastName', {
                                rules: [
                                  { required: true, message: intl.formatMessage(cw.formErrorMsgInputLastName) }
                                ],
                                initialValue: user.last_name
                              })(
                                <Input autoComplete="off" disabled={submitting} />
                              )}
                            </Form.Item>
                          </p>
                        </Col>
                        <Col lg={24}>
                          <p>
                            <div className="label"><FormattedMessage id="Label.Email" defaultMessage="Email address" /></div>
                            <Form.Item required={true} colon={false} label={null}>
                              {getFieldDecorator('email', {
                                validate: [
                                  // {
                                  //     trigger: 'onBlur',
                                  //     rules: [{ validator: this.asyncEmail }]
                                  // },
                                  {
                                    trigger: 'onChange',
                                    rules: [
                                      { required: true, message: intl.formatMessage(cw.formErrorMsgInputEmail) },
                                      { type: 'email', message: intl.formatMessage(cw.formErrorMsgCheckEmail) }
                                    ]
                                  }
                                ],
                                initialValue: user.email
                              })(
                                <Input autoComplete="off" disabled={submitting} />
                              )}
                            </Form.Item>
                          </p>
                        </Col>
                        <Col lg={12}>
                          <p>
                            <div className="label"><FormattedMessage id="Label.PhoneNumber" defaultMessage="Phone number" /></div>
                            <Form.Item required={true} colon={false} label={null}>
                              {getFieldDecorator('msisdn', {
                                rules: [
                                  { required: true, message: intl.formatMessage(cw.formErrorMsgInputPhone) }
                                ],
                                initialValue: user.msisdn
                              })(
                                <Input autoComplete="off" disabled={submitting} />
                              )}
                            </Form.Item>
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={24} style={{ textAlign: 'right' }}>
                      <Button type="ghost" esoko-account-id="settings_cancel_name" disabled={submitting} onClick={() => this.forms('Profile', false)}>
                        <FormattedMessage id="Button.Cancel" defaultMessage="Cancel" />
                      </Button> {' '}
                      <Button type="primary" esoko-account-id="settings_save_name" loading={submitting} onClick={() => this.save('Profile')}>
                        <FormattedMessage id="Button.Save" defaultMessage="Save" />
                      </Button>
                    </Col>
                  </Row>
                )}
              </div>
            </div>

            {/* Password */}
            <div className={`card ${formPassword ? 'edit' : ''}`}>
              <div className="title">
                <div className="h1"><FormattedMessage id="Settings.Password.Title" defaultMessage="Password" /></div>
                <div className="h2"><FormattedMessage id="Settings.Password.Description" defaultMessage="Update your password here to keep your account secure" /></div>
                <p>&nbsp;</p>
                {formPassword === false && (
                  <Row>
                    <Col lg={16}>
                      <p>
                        <div className="label">*************</div>
                      </p>
                    </Col>
                    <Col lg={8} style={{ textAlign: 'right' }}>
                      <p>&nbsp;</p>
                      <Button type="ghost" disabled={submitting} esoko-account-id="settings_edit_password" onClick={() => this.forms('Password', true)}>
                        <FormattedMessage id="Button.Change" defaultMessage="Change" />
                      </Button>
                    </Col>
                  </Row>
                )}
                {formPassword === true && (
                  <Form>
                    <Row>
                      <Col lg={24}>
                        <Row>
                          {/* <Col lg={8}>
                            <p>
                              <div className="label"><FormattedMessage id="Label.CurrentPassword" defaultMessage="Current Password" /></div>
                              <Form.Item required={true} colon={false} label={null}>
                                {getFieldDecorator('password', {
                                  rules: [
                                    { required: true, message: intl.formatMessage(cw.formErrorMsgInputPassword) },
                                    { min: 6, message: intl.formatMessage(cw.minPasswordChar) }
                                  ]
                                })(
                                  <Input type="password" autoComplete="off" disabled={submitting} />
                                )}
                              </Form.Item>
                            </p>
                          </Col>
                          <Col lg={24}></Col> */}
                          <Col lg={8}>
                            <p>
                              <div className="label"><FormattedMessage id="Label.NewPassword" defaultMessage="New Password" /></div>
                              <Form.Item required={true} colon={false} label={null}>
                                {getFieldDecorator('npassword', {
                                  rules: [
                                    { required: true, message: intl.formatMessage(cw.formErrorMsgInputPassword) },
                                    { min: 6, message: intl.formatMessage(cw.minPasswordChar) }
                                  ]
                                })(
                                  <Input type="password" autoComplete="off" disabled={submitting} />
                                )}
                              </Form.Item>
                            </p>
                          </Col>
                          <Col lg={24}></Col>
                          <Col lg={8}>
                            <p>
                              <div className="label"><FormattedMessage id="Label.ConfirmPassword" defaultMessage="Confirm Password" /></div>
                              <Form.Item required={true} colon={false} label={null}>
                                {getFieldDecorator('cpassword', {
                                  rules: [
                                    { required: true, message: intl.formatMessage(cw.formErrorMsgConfirmPassword) },
                                    { min: 6, message: intl.formatMessage(cw.minPasswordChar) },
                                    { validator: this.confirmPassword }
                                  ]
                                })(
                                  <Input type="password" autoComplete="off" disabled={submitting} />
                                )}
                              </Form.Item>
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={24} style={{ textAlign: 'right' }}>
                        <Button type="ghost" disabled={submitting} esoko-account-id="settings_cancel_password" onClick={() => this.forms('Password', false)}>
                          <FormattedMessage id="Button.Cancel" defaultMessage="Cancel" />
                        </Button> {' '}
                        <Button type="primary" loading={submitting} esoko-account-id="settings_save_password" onClick={() => this.save('Password')}>
                          <FormattedMessage id="Button.Save" defaultMessage="Save" />
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </div>
            </div>

          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (data) => {
    dispatch(authAct.setUser(data))
  }
});

const Settings = Form.create()(ProfileScreen);
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Settings));
