import React, { useEffect, useState, Fragment } from "react";
import { formatNumber } from "../../../utils/helpers";
import "./DashboardElement.styles.scss";
import { Empty, Skeleton } from "antd";
import { Row, Col, Icon } from "antd";

export const Dot = ({ color, size }) => {
  return (
    <i className="fa-solid fa-circle" style={{ color: color, fontSize: size }}></i>
  );
};

export const NoData = () => {
  return (
    <div id={"Dashboard-No-Data"}>
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
  );
};

export const DashboardTable = ({ service, data, isLoading, local = "en" }) => {
  return (
    <div id={"DashboardTable"}>
      <div style={{ height: "100%", flex: "1" }}>
        <div className={"dash-title"}>
          <Dot color={service.color} size="8px" /> <p>{service.title}</p>
        </div>
        {isLoading && <Skeleton paragraph={{ rows: 4 }} />}
        {data && data.length > 0 && (
          <div>
            <Row className={"table-header"}>
              <Col className="header" lg={15}>
                Name
              </Col>
              <Col lg={7}>{service.countName}</Col>
            </Row>
            {data.map((item, index) => (
              <Row className={"table-row"} type={"flex"} key={index + 'dashboard-table'}>
                <Col lg={15}>
                  <div className="data-title">{item[service.nameKey]}</div>
                </Col>
                <Col style={{ paddingLeft: 8 }} lg={7}>
                  {formatNumber(item[service.countKey])}
                </Col>
                <Col lg={2}>
                  <div className="data-action">
                    <i
                      onClick={() => {
                        window.location.assign(service.getHref(item), local);
                      }}
                      className="fa-solid fa-arrow-up-right-from-square"
                    ></i>
                  </div>
                </Col>
              </Row>
            ))}
          </div>
        )}
        {!isLoading && (!data || data.length == 0) && <NoData />}
      </div>
      {!isLoading && data && data.length > 0 && (
        <div>
          <button
            onClick={() => {
              window.location.assign(
                `${service.url}/${local}/${service.exploreUrl}`
              );
            }}
            className={"explore-button"}
          >
            <span>{service.exploreText}</span>
          </button>
        </div>
      )}
    </div>
  );
};

export function DashboardStats({ service, token, data, isLoading }) {
  return (
    <div id={"DashboardCard"}>
      <div
        className={"icon-title"}
        style={{ cursor: "pointer", width: "fit-content" }}
        onClick={() => {
          window.location.assign(service.url);
        }}
      >
        <img
          src={service.icon}
          alt={service.title}
          width={30}
          className="card-icon"
        />
        <span className="title">{service.title}</span>
      </div>
      <div className={"stat-content"}>
        {isLoading ? (
          <Skeleton paragraph={{ rows: 1 }} />
        ) : (
          <Fragment>
            {data ? (
              <Fragment>
                {service.labels.map((label) => (
                  <div id={"DashboardStat"} key={label.title}>
                    <Dot color={label.color} size="12px" />
                    <span className="count-label">{label.title}</span>
                    <div className="count">
                      {formatNumber(data[label.dataId])}
                    </div>
                  </div>
                ))}
              </Fragment>
            ) : (
              <NoData />
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
}
