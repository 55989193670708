import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Menu, Icon, Layout } from "antd";
import { hasP } from "../../utils/helpers";

import "./Sider.scss";

import { ReactComponent as HomeIcon } from "../../assets/img/home.svg";
import { ReactComponent as BuildingIcon } from "../../assets/img/building.svg";
import { ReactComponent as SettingsIcon } from "../../assets/img/settings.svg";
import { ReactComponent as AdminIcon } from "../../assets/img/administrators.svg";
import { ReactComponent as HomeIcon2 } from "../../assets/img/home2.svg";
import { ReactComponent as BuildingIcon2 } from "../../assets/img/building2.svg";
import { ReactComponent as SettingsIcon2 } from "../../assets/img/settings2.svg";
import { ReactComponent as AdminIcon2 } from "../../assets/img/administrators2.svg";

const menus = [
  {
    icon: HomeIcon,
    icon2: HomeIcon2,
    title: <FormattedMessage id="Home" defaultMessage="Dashboard" />,
    route: "home",
    dfs: "main_menu_item_home",
  },
  {
    icon: BuildingIcon,
    icon2: BuildingIcon2,
    title: <FormattedMessage id="Organisation" defaultMessage="Organisation" />,
    route: "organisation",
    dfs: "main_menu_item_organisation",
    role: "CAN_MANAGE_ORGANISATION",
  },
  {
    icon: AdminIcon,
    icon2: AdminIcon2,
    title: <FormattedMessage id="Administrators" defaultMessage="Users" />,
    route: "administrators",
    dfs: "main_menu_item_administrators",
    role: "CAN_MANAGE_USERS",
  },
  {
    icon: SettingsIcon,
    icon2: SettingsIcon2,
    title: <FormattedMessage id="Settings" defaultMessage="Settings" />,
    route: "settings",
    dfs: "main_menu_item_settings",
  },
];

export default class PushSider extends PureComponent {
  state = {
    broken: false,
    siderWidth: 256,
    collapsed: "",
  };

  hanldeOnBreakPoint = (broken) => {
    this.setState({ broken });
  };
  handleSiderCollapse = (collapsed) => {
    const siderWidth = collapsed ? 80 : 256;
    // this.setState((state) => ({ siderWidth, collapsed: state.collapsed }, () => {
    //     this.props.setSiderWidth(siderWidth);
    // }));
    this.setState({ siderWidth }, () => {
      this.props.setSiderWidth(siderWidth);
    });
  };

  render() {
    const { siderWidth } = this.state;
    const {
      utils,
      history: {
        location: { pathname },
      },
    } = this.props;
    const pathnamed = pathname.split("/");
    switch (pathnamed[2]) {
      default:
        var defaultKey = "0";
        break;

      case "organisation":
        defaultKey = "1";
        break;

      case "administrators":
        defaultKey = "2";
        break;

      case "settings":
        defaultKey = "3";
        break;
    }

    return (
      <React.Fragment>
        <Layout.Sider
          id="esoko__sider"
          collapsed={false}
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
            marginTop: 45,
          }}
          onBreakpoint={this.hanldeOnBreakPoint}
          onCollapse={this.handleSiderCollapse}
          breakpoint="md"
          width={siderWidth}
        >
          <Menu defaultSelectedKeys={[defaultKey]} mode="inline">
            {menus.map((menu, i) => {
              return (
                (hasP(menu.role) || !menu.role) && (
                  <Menu.Item key={i}>
                    <Link
                      to={"/" + utils.lang + "/" + menu.route}
                      esoko-account-id={menu.dfs}
                    >
                      <Icon
                        component={
                          parseInt(defaultKey) === i ? menu.icon2 : menu.icon
                        }
                      />
                      <span>{menu.title}</span>
                    </Link>
                  </Menu.Item>
                )
              );
            })}
          </Menu>
        </Layout.Sider>
      </React.Fragment>
    );
  }
}
