export default [
  { name: 'Afghanistan ', countryCode: 'af', dialCode: '+93' },
  { name: 'Albania ', countryCode: 'al', dialCode: '+355' },
  { name: 'Algeria ', countryCode: 'dz', dialCode: '+213' },
  { name: 'American Samoa', countryCode: 'as', dialCode: '+1684' },
  { name: 'Andorra ', countryCode: 'ad', dialCode: '+376' },
  { name: 'Angola', countryCode: 'ao', dialCode: '+244' },
  { name: 'Anguilla ', countryCode: 'ai', dialCode: '+1264' },
  { name: 'Antigua and Barbuda', countryCode: 'ag', dialCode: '+1268' },
  { name: 'Argentina ', countryCode: 'ar', dialCode: '+54' },
  { name: 'Armenia', countryCode: 'am', dialCode: '+374' },
  { name: 'Aruba', countryCode: 'aw', dialCode: '+297' },
  { name: 'Australia', countryCode: 'au', dialCode: '+61' },
  { name: 'Austria', countryCode: 'at', dialCode: '+43' },
  { name: 'Ascention Island', countryCode: 'sh', dialCode: '+247' },
  {
    name: 'Azerbaijan',
    countryCode: 'az',
    dialCode: '+994'
  },

  {
    name: 'Bahamas',
    countryCode: 'bs',
    dialCode: '+1242'
  },
  { name: 'Bahrain', countryCode: 'bh', dialCode: '+973' },
  {
    name: 'Bangladesh',
    countryCode: 'bd',
    dialCode: '+880'
  },
  { name: 'Barbados ', countryCode: 'bb', dialCode: '+1246' },
  {
    name: 'Belarus',
    countryCode: 'by',
    dialCode: '+375'
  },
  { name: 'Belgium ', countryCode: 'be', dialCode: '+32' },
  {
    name: 'Belize',
    countryCode: 'bz',
    dialCode: '+501'
  },
  { name: 'Benin', countryCode: 'bj', dialCode: '+229' },
  { name: 'Bermuda ', countryCode: 'bm', dialCode: '+1441' },
  { name: 'Bhutan, Kingdom of', countryCode: 'bt', dialCode: '+975' },
  { name: 'Bolivia ', countryCode: 'bo', dialCode: '+591' },
  { name: 'Bosnia and Herzegovina ', countryCode: 'ba', dialCode: '+387' },
  { name: 'Bonaire', countryCode: 'bq', dialCode: '+599' },
  { name: 'Belize', countryCode: 'bz', dialCode: '+501' },
  {
    name: 'Botswana',
    countryCode: 'bw',
    dialCode: '+267'
  },
  { name: 'Brazil ', countryCode: 'br', dialCode: '+55' },
  {
    name: 'Brunei',
    countryCode: 'bn',
    dialCode: '+673'
  },
  { name: 'Bulgaria ', countryCode: 'bg', dialCode: '+359' },
  {
    name: 'Burkina Faso',
    countryCode: 'bf',
    dialCode: '+226'
  },
  { name: 'Burundi', countryCode: 'bi', dialCode: '+257' },

  {
    name: 'Cambodia',
    countryCode: 'kh',
    dialCode: '+855'
  },
  {
    name: 'Cameroon',
    countryCode: 'cm',
    dialCode: '+237'
  },
  // { name: 'Canada ', countryCode: 'ca', dialCode: '+1' },
  { name: 'Cape Verde ', countryCode: 'cv', dialCode: '+238' },
  { name: 'Cayman Islands ', countryCode: 'ky', dialCode: '+1345' },
  { name: 'Central African Republic ', countryCode: 'cf', dialCode: '+236' },
  { name: 'Chad ', countryCode: 'td', dialCode: '+235' },
  { name: 'Chile ', countryCode: 'cl', dialCode: '+56' },
  { name: 'China ', countryCode: 'cn', dialCode: '+86' },
  { name: 'Colombia ', countryCode: 'co', dialCode: '+57' },
  { name: 'Comoros, Union of the ', countryCode: 'km', dialCode: '+269' },
  {
    name: 'DR Congo',
    countryCode: 'cd',
    dialCode: '+243'
  },
  { name: 'Congo', countryCode: 'cg', dialCode: '+242' },
  {
    name: 'Cook Islands',
    countryCode: 'ck',
    dialCode: '+682'
  },
  { name: 'Costa Rica ', countryCode: 'cr', dialCode: '+506' },
  {
    name: "Cote D'Ivoire",
    countryCode: 'ci',
    dialCode: '+225'
  },
  { name: 'Croatia', countryCode: 'hr', dialCode: '+385' },
  { name: 'Cuba ', countryCode: 'cu', dialCode: '+53' },
  { name: 'Cyprus ', countryCode: 'cy', dialCode: '+357' },
  { name: 'Czech Republic', countryCode: 'cz', dialCode: '+420' },

  { name: 'Denmark ', countryCode: 'dk', dialCode: '+45' },
  {
    name: 'Djibouti',
    countryCode: 'dj',
    dialCode: '+253'
  },
  { name: 'Dominica ', countryCode: 'dm', dialCode: '+1767' },
  { name: 'Dominican Republic ', countryCode: 'do', dialCode: '+1809' },

  { name: 'Ecuador ', countryCode: 'ec', dialCode: '+593 ' },
  {
    name: 'Egypt',
    countryCode: 'eg',
    dialCode: '+20'
  },
  { name: 'El Salvador ', countryCode: 'sv', dialCode: '+503' },
  {
    name: 'Equatorial Guinea',
    countryCode: 'gq',
    dialCode: '+240'
  },
  {
    name: 'Eritrea ',
    countryCode: 'er',
    dialCode: '+291'
  },
  {
    name: 'Estonia',
    countryCode: 'ee',
    dialCode: '+372'
  },
  {
    name: 'Ethiopia',
    countryCode: 'et',
    dialCode: '+251'
  },

  {
    name: 'Falkland Islands',
    countryCode: 'fk',
    dialCode: '+500'
  },
  { name: 'Faroe Islands ', countryCode: 'fo', dialCode: '+298' },
  { name: 'Fiji ', countryCode: 'fj', dialCode: '+679' },
  { name: 'Finland ', countryCode: 'fi', dialCode: '+358' },
  { name: 'France ', countryCode: 'fr', dialCode: '+33' },
  {
    name: 'French Guiana',
    countryCode: 'gf',
    dialCode: '+594'
  },
  {
    name: 'French Polynesia',
    countryCode: 'pf',
    dialCode: '+689'
  },
  { name: 'Gabon', countryCode: 'ga', dialCode: '+241' },
  { name: 'Gambia, The ', countryCode: 'gm', dialCode: '+220' },
  {
    name: 'Georgia',
    countryCode: 'ge',
    dialCode: '+995'
  },
  { name: 'Germany ', countryCode: 'de', dialCode: '+49' },
  { name: 'Ghana', countryCode: 'gh', dialCode: '+233' },
  { name: 'Gibraltar ', countryCode: 'gi', dialCode: '+350' },
  { name: 'Greece ', countryCode: 'gr', dialCode: '+30' },
  { name: 'Greenland ', countryCode: 'gl', dialCode: '+299' },
  { name: 'Grenada ', countryCode: 'gd', dialCode: '+1473' },
  { name: 'Guadeloupe', countryCode: 'gp', dialCode: '+590' },
  { name: 'Guam', countryCode: 'gu', dialCode: '+1671' },
  { name: 'Guatemala ', countryCode: 'gt', dialCode: '+502' },
  {
    name: 'Guinea',
    countryCode: 'gn',
    dialCode: '+224'
  },
  {
    name: 'Guinea-Bissau',
    countryCode: 'gw',
    dialCode: '+245'
  },
  {
    name: 'Guyana',
    countryCode: 'gy',
    dialCode: '+592'
  },

  { name: 'Haiti ', countryCode: 'ht', dialCode: '+509' },
  {
    name: 'Vatican City',
    countryCode: 'va',
    dialCode: '+418'
  },
  { name: 'Honduras ', countryCode: 'hn', dialCode: '+504' },
  { name: 'Hong Kong ', countryCode: 'hk', dialCode: '+852' },
  { name: 'Hungary ', countryCode: 'hu', dialCode: '+36' },

  { name: 'Iceland ', countryCode: 'is', dialCode: '+354' },
  { name: 'India ', countryCode: 'in', dialCode: '+91' },
  {
    name: 'Indonesia',
    countryCode: 'id',
    dialCode: '+62'
  },
  { name: 'Iran', countryCode: 'ir', dialCode: '+98' },
  { name: 'Iraq ', countryCode: 'iq', dialCode: '+964' },
  { name: 'Ireland ', countryCode: 'ie', dialCode: '+353' },
  { name: 'Israel ', countryCode: 'il', dialCode: '+972' },
  { name: 'Italy ', countryCode: 'it', dialCode: '+39' },

  { name: 'Jamaica ', countryCode: 'jm', dialCode: '+1876' },
  { name: 'Japan ', countryCode: 'jp', dialCode: '+81' },
  { name: 'Jordan', countryCode: 'jo', dialCode: '+962' },
  {
    name: 'Kenya',
    countryCode: 'ke',
    dialCode: '+254'
  },
  {
    name: 'Kiribati',
    countryCode: 'ki',
    dialCode: '+686'
  },
  {
    name: 'North Korea',
    countryCode: 'kp',
    dialCode: '+850'
  },
  {
    name: 'South Korea',
    countryCode: 'kr',
    dialCode: '+82'
  },
  { name: 'Kuwait ', countryCode: 'kw', dialCode: '+965' },
  {
    name: 'Kyrgyzstan',
    countryCode: 'kg',
    dialCode: '+996'
  },

  {
    name: 'Laos',
    countryCode: 'la',
    dialCode: '+856'
  },
  {
    name: 'Latvia',
    countryCode: 'lv',
    dialCode: '+371'
  },
  { name: 'Lebanon ', countryCode: 'lb', dialCode: '+961' },
  { name: 'Lesotho', countryCode: 'ls', dialCode: '+266' },
  { name: 'Liberia ', countryCode: 'lr', dialCode: '+231' },
  {
    name: 'Libya',
    countryCode: 'ly',
    dialCode: '+218'
  },
  { name: 'Liechtenstein ', countryCode: 'li', dialCode: '+423' },
  {
    name: 'Lithuania',
    countryCode: 'lt',
    dialCode: '+370'
  },
  { name: 'Luxembourg ', countryCode: 'lu', dialCode: '+352' },

  { name: 'Macau ', countryCode: 'mo', dialCode: '+853' },
  {
    name: 'Macedonia',
    countryCode: 'mk',
    dialCode: '+389'
  },
  {
    name: 'Madagascar',
    countryCode: 'mg',
    dialCode: '+261'
  },
  {
    name: 'Malawi',
    countryCode: 'mw',
    dialCode: '+265'
  },
  { name: 'Malaysia ', countryCode: 'my', dialCode: '+60' },
  { name: 'Maldives ', countryCode: 'mv', dialCode: '+960' },
  {
    name: 'Mali',
    countryCode: 'ml',
    dialCode: '+223'
  },
  { name: 'Malta ', countryCode: 'mt', dialCode: '+356' },
  {
    name: 'Marshall Islands',
    countryCode: 'mh',
    dialCode: '+692'
  },
  { name: 'Martinique', countryCode: 'mq', dialCode: '+596' },
  { name: 'Mauritania ', countryCode: 'mr', dialCode: '+222' },
  { name: 'Mauritius ', countryCode: 'mu', dialCode: '+230' },
  { name: 'Mexico ', countryCode: 'mx', dialCode: '+52' },
  {
    name: 'Micronesia',
    countryCode: 'fm',
    dialCode: '+691'
  },
  { name: 'Moldova', countryCode: 'md', dialCode: '+373' },
  { name: 'Monaco, Principality of', countryCode: 'mc', dialCode: '+377' },
  {
    name: 'Mongolia',
    countryCode: 'mn',
    dialCode: '+976'
  },
  { name: 'Montserrat ', countryCode: 'ms', dialCode: '+1664' },
  { name: 'Morocco ', countryCode: 'ma', dialCode: '+212' },
  {
    name: 'Mozambique',
    countryCode: 'mz',
    dialCode: '+258'
  },
  {
    name: 'Myanmar',
    countryCode: 'mm',
    dialCode: '+95'
  },

  {
    name: 'Namibia',
    countryCode: 'na',
    dialCode: '+264'
  },
  {
    name: 'Nauru',
    countryCode: 'nr',
    dialCode: '+674'
  },
  { name: 'Nepal ', countryCode: 'np', dialCode: '+977' },
  { name: 'Netherlands ', countryCode: 'nl', dialCode: '+31' },
  {
    name: 'Netherlands Antilles',
    countryCode: 'an',
    dialCode: '+599'
  },
  { name: 'New Caledonia ', countryCode: 'nc', dialCode: '+687' },
  { name: 'New Zealand', countryCode: 'nz', dialCode: '+64' },
  { name: 'Nicaragua ', countryCode: 'ni', dialCode: '+505' },
  { name: 'Niger ', countryCode: 'ne', dialCode: '+227' },
  { name: 'Nigeria ', countryCode: 'ng', dialCode: '+234' },
  { name: 'Niue', countryCode: 'nu', dialCode: '+683' },
  { name: 'Norfolk Island ', countryCode: 'nf', dialCode: '+672' },
  {
    name: 'Northern Mariana Islands',
    countryCode: 'mp',
    dialCode: '+1670'
  },
  { name: 'Norway ', countryCode: 'no', dialCode: '+47' },

  {
    name: 'Oman',
    countryCode: 'om',
    dialCode: '+968'
  },

  {
    name: 'Pakistan',
    countryCode: 'pk',
    dialCode: '+92'
  },
  {
    name: 'Palau',
    countryCode: 'pw',
    dialCode: '+680'
  },
  { name: 'Palestinian State', countryCode: 'ps', dialCode: '+970' },
  { name: 'Panama ', countryCode: 'pa', dialCode: '+507' },
  {
    name: 'Papua New Guinea',
    countryCode: 'pg',
    dialCode: '+675'
  },
  { name: 'Paraguay ', countryCode: 'py', dialCode: '+595' },
  { name: 'Peru ', countryCode: 'pe', dialCode: '+51' },
  { name: 'Philippines ', countryCode: 'ph', dialCode: '+63' },
  { name: 'Poland ', countryCode: 'pl', dialCode: '+48' },
  { name: 'Portugal ', countryCode: 'pt', dialCode: '+351' },
  { name: 'Puerto Rico ', countryCode: 'pr', dialCode: '+1939' },

  { name: 'Qatar, State of ', countryCode: 'qa', dialCode: '+974 ' },

  {
    name: 'Reunion',
    countryCode: 're',
    dialCode: '+262'
  },
  { name: 'Romania ', countryCode: 'ro', dialCode: '+40' },
  { name: 'Russian Federation ', countryCode: 'ru', dialCode: '+7' },
  {
    name: 'Rwanda',
    countryCode: 'rw',
    dialCode: '+250'
  },

  { name: 'Saint Helena', countryCode: 'sh', dialCode: '+290' },
  {
    name: 'Saint Kitts and Nevis',
    countryCode: 'kn',
    dialCode: '+1869'
  },
  { name: 'Saint Lucia', countryCode: 'lc', dialCode: '+1758' },
  { name: 'Saint Pierre and Miquelon ', countryCode: 'pm', dialCode: '+508' },
  {
    name: 'Saint Vincent and the Grenadines ',
    countryCode: 'vc',
    dialCode: '+1784'
  },
  { name: 'Samoa', countryCode: 'ws', dialCode: '+685' },
  { name: 'San Marino ', countryCode: 'sm', dialCode: '+378' },
  { name: 'Sao Tome and Principe ', countryCode: 'st', dialCode: '+239' },
  { name: 'Saudi Arabia ', countryCode: 'sa', dialCode: '+966' },
  { name: 'Senegal ', countryCode: 'sn', dialCode: '+221' },
  { name: 'Seychelles ', countryCode: 'sc', dialCode: '+248' },
  { name: 'Sierra Leone ', countryCode: 'sl', dialCode: '+232' },
  { name: 'Singapore ', countryCode: 'sg', dialCode: '+65' },
  { name: 'Slovakia', countryCode: 'sk', dialCode: '+421' },
  { name: 'Slovenia ', countryCode: 'si', dialCode: '+386' },
  {
    name: 'Solomon Islands',
    countryCode: 'sb',
    dialCode: '+677'
  },
  {
    name: 'Somalia',
    countryCode: 'so',
    dialCode: '+252'
  },
  {
    name: 'South Africa',
    countryCode: 'za',
    dialCode: '+27'
  },
  { name: 'Spain ', countryCode: 'es', dialCode: '+34' },
  {
    name: 'Sri Lanka',
    countryCode: 'lk',
    dialCode: '+94'
  },
  {
    name: 'Sudan',
    countryCode: 'sd',
    dialCode: '+249'
  },
  {
    name: 'Suriname',
    countryCode: 'sr',
    dialCode: '+597'
  },
  { name: 'Swaziland', countryCode: 'sz', dialCode: '+268' },
  { name: 'Sweden ', countryCode: 'se', dialCode: '+46' },
  { name: 'Switzerland ', countryCode: 'ch', dialCode: '+41' },
  {
    name: 'Syria',
    countryCode: 'sy',
    dialCode: '+963'
  },
  {
    name: 'Serbia',
    countryCode: 'rs',
    dialCode: '+381'
  },

  { name: 'Taiwan', countryCode: 'tw', dialCode: '+886' },
  {
    name: 'Tajikistan',
    countryCode: 'tj',
    dialCode: '+992'
  },
  {
    name: 'Tanzania',
    countryCode: 'tz',
    dialCode: '+255'
  },
  { name: 'Thailand', countryCode: 'th', dialCode: '+66' },
  {
    name: 'Togo',
    countryCode: 'tg',
    dialCode: '+228'
  },
  { name: 'Tokelau', countryCode: 'tk', dialCode: '+690' },
  {
    name: 'Tonga',
    countryCode: 'to',
    dialCode: '+676'
  },
  { name: 'Trinidad and Tobago ', countryCode: 'tt', dialCode: '+1868' },
  { name: 'Tunisia ', countryCode: 'tn', dialCode: '+216' },
  { name: 'Turkey ', countryCode: 'tr', dialCode: '+90' },
  {
    name: 'Turkmenistan',
    countryCode: 'tm',
    dialCode: '+993'
  },
  { name: 'Turks and Caicos Islands ', countryCode: 'tc', dialCode: '+1649' },
  {
    name: 'Tuvalu',
    countryCode: 'tv',
    dialCode: '+688'
  },

  { name: 'Uganda', countryCode: 'ug', dialCode: '+256' },
  {
    name: 'Ukraine',
    countryCode: 'ua',
    dialCode: '+380'
  },
  {
    name: 'United Arab Emirates',
    countryCode: 'ae',
    dialCode: '+971'
  },
  {
    name: 'United Kingdom',
    countryCode: 'gb',
    dialCode: '+44'
  },
  { name: 'United States', countryCode: 'us', dialCode: '+1' },
  {
    name: 'Uruguay',
    countryCode: 'uy',
    dialCode: '+598'
  },
  {
    name: 'Uzbekistan',
    countryCode: 'uz',
    dialCode: '+998'
  },

  {
    name: 'Vanuatu',
    countryCode: 'vu',
    dialCode: '+678'
  },
  { name: 'Venezuela ', countryCode: 've', dialCode: '+58' },
  { name: 'Vietnam ', countryCode: 'vn', dialCode: '+84' },
  { name: 'Virgin Islands, British ', countryCode: 'vi', dialCode: '+1284' },

  { name: 'Wallis and Futuna Islands ', countryCode: 'wf', dialCode: '+681' },

  { name: 'Yemen', countryCode: 'ye', dialCode: '+967' },
  {
    name: 'Zambia',
    countryCode: 'zm',
    dialCode: '+260'
  },
  {
    name: 'Zimbabwe',
    countryCode: 'zw',
    dialCode: '+263'
  }
];
