import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import {
  Form,
  Button,
  notification,
  Icon,
  Tooltip,
  Menu,
  Dropdown,
  Modal,
} from "antd";
import { Row, Col } from "rsuite";
import { FormattedMessage } from "react-intl";
import { hp, cf, cw } from "../../utils";
import * as authAct from "../../store/auth/_authActions";
import "./Administrators.scss";
import AdministratorsForm from "./AdministratorsForm";
import UserRoleEditor from "./components/UserRoleEditor";
import UserItem from "./components/UserItem";

export class AdministratorsScreen extends Component {
  state = {
    view: "active",
    ///roles
    updateUser: undefined,
    editRole: undefined,
    showUpdateRoleModal: false,
    adminForm: false,
    revokeUser: false,
    submitting: false,
    loading: false,
    data: [],
    active: [],
    pending: [],
    expired: [],
  };

  componentWillMount = () => {
    const { setPageTitle } = this.props;
    setPageTitle(`Administrators - Esoko`);
  };

  componentDidMount() {
    this.getData();
  }

  getData = (data = true) => {
    if (this.props.auth.account.id) {
      this.setState({ loading: true, active: [], pending: [], expired: [] });
      let { pending, expired, view } = this.state;
      axios({
        method: "GET",
        url: `${
          cf.SSO_URL
        }/accounts/${this.props.auth.account.id.trim()}/invitations`,
        headers: { "x-access-token": hp.getCookie("user_token") },
      }).then((response) => {
        // this.setState({ loading: false });
        const res = response.data;
        if (res.statusCode === 200) {
          res.data.items.map((row) => {
            if (row.expired === true) {
              expired = expired.concat(row);
            } else if (row.status.toLowerCase() === "pending") {
              pending = pending.concat(row);
            }
            return null;
          });
          this.setState({ pending, expired });
        }
      });

      axios({
        method: "GET",
        url: `${
          cf.SSO_URL
        }/accounts/${this.props.auth.account.id.trim()}/users`,
        headers: { "x-access-token": hp.getCookie("user_token") },
      })
        .then((response) => {
          this.setState({ loading: false });
          const res = response.data;
          if (res.statusCode === 200) {
            if (data === true) {
              this.setState({ data: res.data, active: res.data });
            } else {
              switch (view) {
                default:
                  this.setState({ data: res.data, active: res.data });
                  break;
                case "pending":
                  this.setState({ data: pending, active: res.data });
                  break;
                case "expired":
                  this.setState({ data: expired, active: res.data });
                  break;
              }
            }
          }
        })
        .catch((e) => {
          this.setState({ loading: false });
        });
    }
  };

  revoke = () => {
    const {
      intl,
      auth: { account },
    } = this.props;
    const { data, pending, active, expired, revokeUser, view } = this.state;
    this.setState({ submitting: true });
    let route = `invitations/${revokeUser}`;
    if (["active"].includes(view)) {
      route = `users/${revokeUser}`;
    }
    axios({
      method: "DELETE",
      url: `${cf.SSO_URL}/accounts/${account.id}/${route}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": hp.getCookie("user_token"),
      },
    })
      .then((response) => {
        this.setState({ submitting: false });
        const res = response.data;
        if (res.statusCode === 200) {
          this.setState({
            data: data.filter((row) => row.id !== revokeUser),
            pending: pending.filter((row) => row.id !== revokeUser),
            active: active.filter((row) => row.id !== revokeUser),
            expired: expired.filter((row) => row.id !== revokeUser),
            revokeUser: false,
          });
          notification.success({
            message: intl.formatMessage(cw["Success"]),
            description: intl.formatMessage(cw["Admin.Delete.Success"]),
          });
        } else {
          notification.error({
            message: intl.formatMessage(cw["Failed"]),
            description: intl.formatMessage(cw["Admin.Delete.Failed"]),
          });
        }
      })
      .catch((err) => {
        this.setState({ submitting: false });
        notification.error({
          message: intl.formatMessage(cw["Failed"]),
          description: intl.formatMessage(cw["Admin.Delete.Failed"]),
        });
      });
  };
  updateRole = () => {
    const { intl } = this.props;
    const { active, updateUser, editRole: role } = this.state;
    this.setState({ submitting: true });
    authAct
      .UpdateUserRole(updateUser.id, role)
      .then(() => {
        const update = active.map((user) =>
          updateUser.id === user.id
            ? {
                ...user,
                role,
              }
            : user
        );
        this.setState({
          active: update,
          data: update,
          showUpdateRoleModal: false,
          submitting: false,
        });
        notification.success({
          message: intl.formatMessage(cw["Success"]),
          description: intl.formatMessage(cw["user_updateRole_success"]),
        });
      })
      .catch((e) => {
        this.setState({ submitting: false });
        notification.error({
          message: intl.formatMessage(cw["Failed"]),
          description: intl.formatMessage(cw["user_updateRole_failed"]),
        });
      });
  };
  switchView = (view, data) => {
    this.setState({ view, data });
  };

  onRoleClick = (user) => {
    this.setState({
      editRole: user.role,
      updateUser: user,
      showUpdateRoleModal: true,
    });
  };
  onRevokeUser = (id) => {
    this.setState({ revokeUser: id });
  };
  render() {
    const {
      intl,
      auth: { user },
    } = this.props;
    const {
      data,
      updateUser,
      showUpdateRoleModal,
      editRole,
      pending,
      active,
      expired,
      view,
      revokeUser,
      submitting,
      loading,
    } = this.state;
    const updateRoleDisabled = updateUser
      ? editRole === updateUser.role
      : false;
    return (
      <React.Fragment>
        <div id="accounts__administrators" className="animated fadeIn faster">
          <div className="top">
            <div className="h1">
              <FormattedMessage id="Users.Title" defaultMessage="Users" />
            </div>
            <div className="h2">
              <FormattedMessage
                id="Administrators.Title.Description"
                defaultMessage="Users of your organization are found here"
              />
            </div>
          </div>

          <div className="lists">
            <div className="nav">
              <Row>
                <Col lg={18}>
                  <Button
                    type={view === "active" ? "primary" : "link"}
                    htmlType="button"
                    shape={view === "active" ? "round" : "link"}
                    ghost
                    sso-id="administrators-switchto-active"
                    onClick={() => this.switchView("active", active)}
                  >
                    <FormattedMessage
                      id="Administrators.Title"
                      defaultMessage="Users"
                    />{" "}
                    ({active.length})
                  </Button>{" "}
                  <Button
                    type={view === "pending" ? "primary" : "link"}
                    htmlType="button"
                    shape={view === "pending" ? "round" : "link"}
                    ghost
                    sso-id="administrators-switchto-pending"
                    onClick={() => this.switchView("pending", pending)}
                  >
                    <FormattedMessage id="Pending" defaultMessage="Pending" /> (
                    {pending.length})
                  </Button>{" "}
                  <Button
                    type={view === "expired" ? "primary" : "link"}
                    htmlType="button"
                    shape={view === "expired" ? "round" : "link"}
                    ghost
                    sso-id="administrators-switchto-expired"
                    onClick={() => this.switchView("expired", expired)}
                  >
                    <FormattedMessage id="Expired" defaultMessage="Expired" /> (
                    {expired.length})
                  </Button>
                </Col>
                <Col lg={6} style={{ textAlign: "right" }}>
                  <Button
                    type="primary"
                    htmlType="button"
                    onClick={() => this.setState({ adminForm: true })}
                  >
                    <FormattedMessage
                      id="AddAdministrator"
                      defaultMessage="Add User"
                    />
                  </Button>
                </Col>
              </Row>
            </div>
            {loading === false && data.length > 0 && (
              <div className="table">
                <Row className="title">
                  <Col lg={7}>
                    <FormattedMessage id="Label.Name" defaultMessage="Name" />
                  </Col>
                  <Col lg={9}>
                    <FormattedMessage
                      id="Label.EmailAddress"
                      defaultMessage="Email Address"
                    />
                  </Col>
                  <Col lg={6}>
                    <FormattedMessage id="Label.Role" defaultMessage="Role" />
                  </Col>
                  <Col lg={1} />
                </Row>
                {data.map((row) => (
                  <UserItem
                    user={row}
                    isActive={view === "active"}
                    className={`list ${view}`}
                    key={row.id}
                    onRoleClick={this.onRoleClick}
                    onRevokeUser={this.onRevokeUser}
                  />
                ))}
              </div>
            )}

            {loading === true && (
              <div
                style={{
                  background: "#fafafa",
                  color: "#878787",
                  fontStyle: "italic",
                  textAlign: "center",
                  padding: 10,
                  marginTop: 50,
                }}
              >
                loading ...
              </div>
            )}

            {loading === false && data.length === 0 && (
              <div
                style={{
                  background: "#fafafa",
                  color: "#878787",
                  fontStyle: "italic",
                  textAlign: "center",
                  padding: 10,
                  marginTop: 50,
                }}
              >
                <FormattedMessage
                  id="NoInvitations"
                  defaultMessage="There are no {view} invitations"
                  values={{ view }}
                />
              </div>
            )}
          </div>
        </div>

        <AdministratorsForm
          {...this.props}
          visible={this.state.adminForm}
          onClose={() => {
            this.setState({ adminForm: false });
          }}
          onFinish={() => {
            this.getData(false);
          }}
        />
        {/*---->Edit Role Modal*/}
        <Modal
          footer={[
            <Button
              type={"link"}
              account-id="admin-revoke-cancel"
              onClick={() => this.setState({ showUpdateRoleModal: false })}
            >
              <FormattedMessage id="Cancel" defaultMessage="Cancel" />
            </Button>,
            <Button
              type={updateRoleDisabled ? "ghost" : "primary"}
              disabled={updateRoleDisabled}
              loading={submitting}
              account-id="admin-update-role-button"
              onClick={this.updateRole}
            >
              <FormattedMessage
                id="User.UpdateRole.Button"
                defaultMessage="Update Role"
              />
            </Button>,
          ]}
          onCancel={() => {
            if (submitting) return;
            this.setState({ showUpdateRoleModal: false });
          }}
          maskClosable={false}
          title={intl.formatMessage(cw["Modal.Title.EditRole"])}
          visible={showUpdateRoleModal}
        >
          {updateUser && (
            <UserRoleEditor
              disabled={user.user_id === updateUser.id}
              role={editRole}
              onChange={(role) => this.setState({ editRole: role })}
              intl={intl}
              user={updateUser}
            />
          )}
        </Modal>
        {/*Edit Role Modal<----*/}

        <Modal
          title={intl.formatMessage(cw["Administrators.Revoke.Title"])}
          visible={revokeUser ? true : false}
          className="admin__modal"
          onCancel={() => this.setState({ revokeUser: false })}
          maskClosable={false}
          footer={[
            <Button
              type="link"
              account-id="admin-revoke-cancel"
              onClick={() => this.setState({ revokeUser: false })}
            >
              <FormattedMessage id="Cancel" defaultMessage="Cancel" />
            </Button>,
            <Button
              type="primary"
              className="submitBtn"
              loading={submitting}
              account-id="admin-revoke-button"
              onClick={this.revoke}
            >
              <FormattedMessage
                id="Administrators.Revoke.Button"
                defaultMessage="Revoke Access"
              />
            </Button>,
          ]}
        >
          <div style={{ color: "#AD0000" }}>
            <FormattedMessage
              id="Administrators.Revoke.Text"
              defaultMessage="Performing this action will remove access to your organization for this admin. Do you want to continue?"
            />
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setAccount: (data) => {
    dispatch(authAct.setAccount(data));
  },
});

const Administrators = Form.create()(AdministratorsScreen);
export default connect(mapStateToProps, mapDispatchToProps)(Administrators);
