import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import bugsnag from 'bugsnag-js';
import createPlugin from 'bugsnag-react';

import configureStore, { history } from './store/_store';

import 'rsuite/lib/styles/index.less';
import './App.scss';
import './index.less';

import App from './App.jsx';

import * as serviceWorker from './serviceWorker';
import { getCookie, IsStringJSON } from './utils/helpers';
import { REACT_APP_VERSION } from './utils/config';

const store = configureStore();

const appUser = IsStringJSON(getCookie('user')) ? JSON.parse(getCookie('user')) || {} : {};

let whichMode;
if (window.location.hostname.includes('accounts.qa')) {
    whichMode = 'staging';
} else if (window.location.hostname.includes('accounts-sso.esoko.com')) {
    whichMode = 'production';
} else {
    whichMode = 'development';
}

let bugsnagClient;
let bugsnagApiKey = '583aaeec921a9668107633caeab6e720';
if (appUser) {
    bugsnagClient = bugsnag({
        releaseStage: whichMode,
        notifyReleaseStages: ['production', 'staging'],
        apiKey: bugsnagApiKey,
        appVersion: `${REACT_APP_VERSION}`,
        autoCaptureSessions: true,
        autoNotify: false,
        autoNotifyUnhandledRejection: false,
        beforeSend: function (report) {
            report.metaData = {
                user: {
                    ...appUser,
                    user_token: getCookie('user_token'),
                    appVersion: `${REACT_APP_VERSION}`
                }
            };
        }
    });
} else {
    bugsnagClient = bugsnag({
        apiKey: bugsnagApiKey,
        releaseStage: whichMode,
        notifyReleaseStages: ['production', 'staging'],
        appVersion: `${REACT_APP_VERSION}`,
        autoCaptureSessions: true,
        autoNotify: false,
        autoNotifyUnhandledRejection: false,
        beforeSend: function (report) {
            report.metaData = {
                user: {
                    user_token: getCookie('user_token') || '',
                    appVersion: `${REACT_APP_VERSION}`
                }
            };
        }
    });
}

const ErrorBoundary = bugsnagClient.use(createPlugin(React));
const FallbackComponent = ({ error, info }) => (
    <div>An error has occurred. (Code: AC0001) </div>
);

ReactDOM.render(
    <ErrorBoundary FallbackComponent={FallbackComponent}>
        <Provider store={store}>
            <App history={history} />
        </Provider>
    </ErrorBoundary>, document.getElementById("root"));

serviceWorker.unregister();
