import React, { useState, useEffect } from "react";
import { cf, cw } from "../../../utils";
import { Button, Menu, Dropdown, Icon, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import { Row, Col } from "rsuite";

function UserRoleEditor({ intl , user, role, onChange , disabled = false  }) {
  return (
    <div id="UserRoleEditor">
      <Row className={"role-form-body"}>
        <Col sm={12} className="person active">
          <div className={`avatar`}>
            {user.firstName[0]}
            {user.lastName[0]}
          </div>
          <div className="name middle">
            {user.firstName} {user.lastName}
          </div>
          <div className="clear" />
        </Col>
        <Col sm={12}>
          <Dropdown
            overlay={
              <Menu
                style={{
                  maxWidth: 350,
                }}
                sso-id="user-change-roles-menu"
              >
                {cf.SYSTEM_ROLES.map((item) => (
                  <Menu.Item disabled={item === role ? false : disabled} onClick={() => onChange(item)}>
                    <div className="update-role-row">
                      <p className="role-name">
                        <span
                          style={{
                            color: item === role ? "#094A9F" : "inherit",
                          }}
                        >
                          {intl.formatMessage(cw[item])}
                        </span>

                        <span>
                          {item == role && (
                            <Icon
                              type="check"
                              style={{
                                fontSize: 11,
                                color: "#094A9F",
                              }}
                            />
                          )}
                        </span>
                      </p>
                      <p
                        style={{
                          fontSize: 13,
                          lineHeight: "16px",
                        }}
                      >
                        {intl.formatMessage(cw[`${item}_description`])}
                      </p>
                    </div>
                  </Menu.Item>
                ))}
              </Menu>
            }
            trigger={["click"]}
          >
            <Button
              style={{
                minWidth: 170,
                float: "right",
              }}
              type="default"
              disabled={disabled}
              sso-id="user-change-roles-trigger"
            >
              <FormattedMessage {...cw[role || "manager"]} />
              <Icon type="down" />
            </Button>
          </Dropdown>
        </Col>
      </Row>
    </div>
  );
}

export default UserRoleEditor;
