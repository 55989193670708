import React, { PureComponent } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import libphonenumber from 'google-libphonenumber';
import { Form, Input, Button, Row, Col, Select } from 'antd';
import { FormattedMessage, injectIntl } from "react-intl";
import { cc, cf, cw } from "../../utils";

import AuthHeader from './Header';

class SignUp extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            country: {
                location: {},
                country_name: 'Ghana',
                country_code: ''
            },
            recaptcha: null
        };
    }

    componentDidMount() {
        this.ipinfo();
    }

    ipinfo = () => {
        axios({
            method: 'POST',
            url: `${cf.SSO_URL}/ipinfo`,
            json: true
        }).then((res) => {
            if (res.data.country_name) {
                this.setState({ country: res.data, fetching: false });
            }
        }).catch((err) => {
            this.setState({ fetching: false });
        });
    }

    onChangeRecap = value => {
        this.setState({ recaptcha: value });
    };

    asyncAvailable = async (name, value) => {
        return await axios({
            method: 'GET',
            url: `${cf.SSO_URL}/auths/users?${name}=${value}`,
            json: true
        }).then((res) => {
            if (res.data.statusCode === 200) {
                return false;
            } else {
                return true;
            }
        }).catch((err) => {
            return true;
        });
    };

    chheckEmailAddress = async (rule, value, callback) => {
        if (!value) {
            callback();
        }
        if (value) {
            var checker = await this.asyncAvailable('email', value);
            if (checker === false) {
                callback(`${value} already exists`);
            } else {
                callback();
            }
        }
    }

    checkNumber = phone => {
        try {
            return libphonenumber.PhoneNumberUtil.getInstance().isPossibleNumber(
                libphonenumber.PhoneNumberUtil.getInstance().parse(phone, '')
            );
        } catch (error) {
            return false;
        }
    };

    isPhoneNumber = phone => {
        try {
            return libphonenumber.PhoneNumberUtil.getInstance().isValidNumber(libphonenumber.PhoneNumberUtil.getInstance().parse(phone, ''));
        } catch (error) {
            return false;
        }
    };

    checkPhoneNumber = async (rule, value, callback) => {
        const num = this.isPhoneNumber(`${this.props.form.getFieldValue('prefix')}${value}`);
        const state = this.checkNumber(`${this.props.form.getFieldValue('prefix')}${value}`);

        const checker = await this.asyncAvailable('msisdn', `${this.props.form.getFieldValue('prefix')}${value.replace(/^0/, '')}`);
        if (checker === false) {
            callback(`${value} already exists`);
        }
        if (!value) {
            callback();
        }
        if (!num) {
            callback(this.props.intl.formatMessage(cw.formErrorMsgNotValidPhoneNumber));
        }
        if (!state) {
            callback(this.props.intl.formatMessage(cw.formErrorMsgNotValidPhoneNumber));
        }
        callback();
    };

    confirmPassword = (rule, value, callback) => {
        if (!value) {
            callback();
        }
        if (value !== this.props.form.getFieldValue('password')) {
            callback(this.props.intl.formatMessage(cw.formErrorMsgMismatchPassword));
        }
        callback();
    };

    _signup = e => {
        e.preventDefault();
        const { form: { validateFieldsAndScroll }, signUp, intl, utils: { lang } } = this.props;
        const newPhone = `${this.props.form.getFieldValue('prefix')}${this.props.form.getFieldValue('msisdn').replace(/^0/, '')}`;
        validateFieldsAndScroll((err, vals) => {
            if (!err) {
                let data = {
                    firstName: vals.firstName,
                    lastName: vals.lastName,
                    email: vals.email.trim(),
                    msisdn: `+${newPhone.trim().slice(1)}`,
                    password: vals.password,
                    country: vals.country || this.state.country.country_name,
                    organizationName: vals.organizationName,
                    // recaptcha: this.state.recaptcha,
                    industry: vals.industry,
                    preferences: { language: lang || 'en' }
                };
                signUp(intl, lang, data);
            }
        });
    };

    render() {
        const { form: { getFieldDecorator }, utils, auth: { signingIn }, intl } = this.props;

        const prefixSelector = getFieldDecorator('prefix', {
            initialValue: `+${this.state.country.location && this.state.country.location.calling_code
                    ? this.state.country.location.calling_code
                    : '233'
                }`
        })(
            <Select size="large" placeholder="+233" showSearch
                onChange={e => {
                    this.props.form.setFields({
                        country: {
                            value: cc.find(country => country.dialCode === e).name
                        }
                    });
                }}
            >
                {cc.map(country => (
                    <Select.Option key={country.countryCode} value={country.dialCode}>
                        {country.dialCode}
                    </Select.Option>
                ))}
            </Select>
        );

        return (
            <React.Fragment>
                <Form id="esoko__signin" onSubmit={this._signup} hideRequiredMark={true}>
                    <AuthHeader screen="signup" />

                    <Row gutter={16}>
                        <Col span={24}>
                            <div>
                                <Form.Item colon={false} label={intl.formatMessage(cw['Label.Organisation'])}>
                                    {getFieldDecorator('organizationName', {
                                        rules: [
                                            { required: true, message: intl.formatMessage(cw.formErrorMsgInputOrgName) }
                                        ]
                                    })(
                                        <Input size="large" type="text" autoComplete="off" />
                                    )}
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div>
                                <Form.Item colon={false} label={intl.formatMessage(cw['Label.Industry'])}>
                                    {getFieldDecorator('industry', {
                                        rules: [
                                            { required: true, message: intl.formatMessage(cw['required_industry']) }
                                        ]
                                    })(
                                        <Select size="large" showSearch={utils.industries.length > 5}>
                                            {utils.industries.map(industry => (
                                                <Select.Option key={industry} value={industry}>{industry}</Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div>
                                <Form.Item colon={false} label={intl.formatMessage(cw['Label.FirstName'])}>
                                    {getFieldDecorator('firstName', {
                                        rules: [
                                            { required: true, message: intl.formatMessage(cw.formErrorMsgInputFirstName) }
                                        ]
                                    })(
                                        <Input size="large" type="text" autoComplete="off" />
                                    )}
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div>
                                <Form.Item colon={false} label={intl.formatMessage(cw['Label.LastName'])}>
                                    {getFieldDecorator('lastName', {
                                        rules: [
                                            { required: true, message: intl.formatMessage(cw.formErrorMsgInputLastName) }
                                        ]
                                    })(
                                        <Input size="large" type="text" autoComplete="off" />
                                    )}
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item colon={false} label={intl.formatMessage(cw['Label.Email'])}>
                                {getFieldDecorator('email', {
                                    validate: [
                                        {
                                            trigger: 'onBlur',
                                            rules: [{ validator: this.chheckEmailAddress }]
                                        },
                                        {
                                            trigger: 'onChange',
                                            rules: [
                                                { required: true, message: intl.formatMessage(cw.formErrorMsgInputEmail) },
                                                { type: 'email', message: intl.formatMessage(cw.formErrorMsgCheckEmail) }
                                            ]
                                        }
                                    ]
                                })(
                                    <Input size="large" type="email" autoComplete="off" />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        {/* <Col span={12}>
                            <Form.Item colon={false} label={intl.formatMessage(cw['auth_form_country'])}>
                                {countrySelector}
                            </Form.Item>
                        </Col> */}
                        <Col span={24}>
                            <Form.Item colon={false} label={intl.formatMessage(cw['Label.PhoneNumber'])}>
                                {getFieldDecorator('msisdn', {
                                    rules: [
                                        { required: true, message: intl.formatMessage(cw.formErrorMsgInputPhoneNumber) },
                                        { validator: this.checkPhoneNumber, trigger: 'onBlur' }
                                    ]
                                })(
                                    <Input size="large" type="text" addonBefore={prefixSelector} autoComplete="off" />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item colon={false} label={intl.formatMessage(cw['Label.Password'])}>
                                {getFieldDecorator('password', {
                                    rules: [
                                        { required: true, message: intl.formatMessage(cw.formErrorMsgInputPassword) },
                                        { min: 6, message: intl.formatMessage(cw.minPasswordChar) }
                                    ]
                                })(
                                    <Input.Password size="large" type="password" minLength="6" autoComplete="off" />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item colon={false} label={intl.formatMessage(cw['auth_form_confirm_password'])}>
                                {getFieldDecorator('cpassword', {
                                    rules: [
                                        { required: true, message: intl.formatMessage(cw['confirm_password']) },
                                        { min: 6, message: intl.formatMessage(cw.minPasswordChar) },
                                        { validator: this.confirmPassword }
                                    ]
                                })(
                                    <Input.Password size="large" type="password" minLength="6" autoComplete="off" />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="recaptchaDiv">
                                <ReCAPTCHA data-size="compact" ref="recaptcha" size="invisible" sitekey={cf.REACT_RECAPTCHA_SITE_KEY} onChange={this.onChangeRecap} />
                            </div>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button className="submitBtn" block size="large" type="primary" htmlType="submit"
                            loading={signingIn}
                        // disabled={!getFieldsValue(['organisation', 'email'])}
                        >
                            <FormattedMessage id="auth_signup_button" defaultMessage="Sign up" />
                        </Button>
                    </Form.Item>

                    <div className="formLinks">
                        <p style={{ textAlign: 'center' }}>
                            <span className="noAccountText"><FormattedMessage id="auth_signin_got_account" defaultMessage="Already have an account?" /></span>
                            <Link to={`/${utils.lang || 'en'}/signin`}><FormattedMessage id="auth_signin_signin" defaultMessage="Sign in" /></Link>
                        </p>
                    </div>
                </Form>
            </React.Fragment>
        );
    }
}

const SignUpForm = Form.create({ name: 'signup' })(SignUp);
export default injectIntl(SignUpForm);