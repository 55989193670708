import { defineMessages } from 'react-intl';

export default defineMessages({
  'Modal.Title.EditRole' : {
    defaultMessage: 'Edit Role',
    id: 'Modal.Title.EditRole'
  } ,
  manager : {
    defaultMessage: 'Administrator',
    id: 'Roles.Manager'
  },
  collaborator : {
    defaultMessage: 'Collaborator',
    id: 'Roles.Collaborator'
  },
  quality_assurance : {
    defaultMessage: 'Quality Assurance',
    id: 'Roles.QualityAssurance'
  },
  general_viewer : {
    defaultMessage: 'General Viewer',
    id: 'Roles.GeneralViewer'
  },
  dashboard_viewer : {
    defaultMessage: 'Dashboard Viewer',
    id: 'Roles.DashboardViewer'
  },
  manager_description : {
    defaultMessage: 'The user has full access to configure and edit all account components.',
    id: 'Roles.Manager.Description'
  },
  collaborator_description : {
    defaultMessage: 'The user has access to configure and edit all account components except organization and administrators, in account settings.',
    id: 'Roles.Collaborator.Description'
  },
  quality_assurance_description : {
    defaultMessage: 'This user has access to form responses to reject and change response status on Insyt.',
    id: 'Roles.QualityAssurance.Description'
  },
  general_viewer_description : {
    defaultMessage: 'This user has access to view responses, albums and reports on Insyt and has read-only aceess to Analytics.',
    id: 'Roles.GeneralViewer.Description'
  },
  dashboard_viewer_description: {
    defaultMessage: 'This user has read-only access to a default dashboard on Analytics.',
    id: 'Roles.DashboardViewer.Description'
  },
  formErrorMsgInputUserName: {
    defaultMessage: 'Please provide username',
    id: 'required_username'
  },
  formErrorMsgInputPassword: {
    defaultMessage: 'Please provide password',
    id: 'required_password'
  },
  formErrorMsgConfirmPassword: {
    defaultMessage: 'Please confirm password',
    id: 'confirm_password'
  },
  bruteForcePolice: {
    id: 'Notification.bruteForcePolice',
    defaultMessage: 'Account temporarily blocked due to several failed logins. Try again later or request a new password'
  },
  invalidCred: {
    id: 'Notification.invalidCredentials',
    defaultMessage: 'Invalid user credentials'
  },
  signInFailed: {
    id: 'Notification.signInFailed',
    defaultMessage: 'Sign in failed.'
  },
  signInFailedDesc: {
    id: 'Notification.signInFailedDesc',
    defaultMessage: 'Sign in failed. Try again'
  },
  signOutFailed: {
    id: 'Notification.signOutFailed',
    defaultMessage: 'Sign out failed.'
  },
  signOutFailedDesc: {
    id: 'Notification.signOutFaileDesc',
    defaultMessage: 'Sign out failed. Try again'
  },
  formErrorMsgInputFirstName: {
    defaultMessage: 'Please provide first name',
    id: 'required_firstname'
  },
  formErrorMsgInputLastName: {
    defaultMessage: 'Please provide last name',
    id: 'required_lastname'
  },
  formErrorMsgInputPhone: {
    defaultMessage: 'Please provide phone number',
    id: 'required_phone'
  },
  formErrorMsgInputOrgName: {
    defaultMessage: 'Please provide organisation',
    id: 'required_organisation'
  },
  formErrorMsgInputOrgShortName: {
    defaultMessage: 'Please provide short name',
    id: 'required_short_name'
  },
  formErrorMsgInputPhoneNumber: {
    defaultMessage: 'Please provide phone number',
    id: 'required_phone_number'
  },
  formErrorMsgInputEmailMsisdn: {
    id: 'required_email_msisdn',
    defaultMessage: 'Please provide username'
  },
  formErrorMsgInputEmail: {
    id: 'required_email',
    defaultMessage: 'Please provide email'
  },
  delete: {
    id: 'text_delete',
    defaultMessage: 'Delete'
  },
  people: {
    id: 'text_people',
    defaultMessage: 'People'
  },
  person: {
    id: 'App.Text.Person',
    defaultMessage: 'Person'
  },
  addPerson: {
    id: 'App.Text.AddPerson',
    defaultMessage: 'Add person'
  },
  importFromFile: {
    id: 'App.Text.ImportFromFile',
    defaultMessage: 'Import from a file'
  },
  tryImport: {
    id: 'App.Text.TryImport',
    defaultMessage: 'Try import again'
  },
  formErrorMsgProvideGroupName: {
    id: 'App.Form.formErrorMsgProvideGroupName',
    defaultMessage: 'Please provide group name!'
  },
  formErrorMsgNotExceedTwo: {
    id: 'App.Form.formErrorMsgNotExceedTwo',
    defaultMessage: 'Cannot be less than 2 characters'
  },
  formErrorMsgGroupRequired: {
    id: 'App.Form.formErrorMsgGroupRequired',
    defaultMessage: 'Group id is required!'
  },
  add: {
    id: 'App.add',
    defaultMessage: 'Add'
  },
  formMsgCreategroup: {
    id: 'App.Form.formMsgCreategroup',
    defaultMessage: 'Create group'
  },
  formErrorMsgNotExceedFifteen: {
    id: 'App.Form.formErrorMsgNotExceedFifteen',
    defaultMessage: 'Cannot exceed 15 characters!'
  },
  noGroupsFound: {
    id: 'App.Form.noGroupsFound',
    defaultMessage: 'No groups found!'
  },
  searchForGroup: {
    id: 'App.Form.searchForGroup',
    defaultMessage: 'Search for a group'
  },
  selectGroup: {
    id: 'App.Form.selectGroup',
    defaultMessage: 'Select group'
  },
  signOut: {
    id: 'App.Auth.signOut',
    defaultMessage: 'Select group'
  },
  userIcon: {
    id: 'App.userIcon',
    defaultMessage: 'User Icon'
  },
  formErrorMsgCheckEmail: {
    defaultMessage: 'Please check provided email',
    id: 'App.Form.formErrorMsgCheckEmail'
  },
  resetEmailFailed: {
    id: 'Notification.resetEmailFailed',
    defaultMessage: 'Unable to reset password. Please try again'
  },
  noSuchResponse: {
    id: 'Notification.noSuchResponse',
    defaultMessage: 'No response(s) found'
  },
  paymentInitiationFailed: {
    id: 'paymentInitiationFailed',
    defaultMessage: 'Payment failed. Please try again.'
  },
  paymentFailed: {
    id: 'paymentFailed',
    defaultMessage: 'Top up failed'
  },
  deleteReviewExerciseSuccess: {
    id: 'Notification.deleteReviewExerciseSuccess',
    defaultMessage: 'review exercise deleted sucessfully'
  },
  verifyTokenRequstFailed: {
    id: 'Notification.verifyTokenRequstFailed',
    defaultMessage: 'Verification token request failed'
  },
  fetchScheduledSMSFailed: {
    id: 'Notification.fetchScheduledSMSFailed',
    defaultMessage: 'Fetching scheduled sms failed'
  },

  noSuchList: {
    id: 'Notification.noSuchList',
    defaultMessage: 'No lists found'
  },
  responseAssignmentToAgentSuccess: {
    id: 'Notification.responseAssignmentToAgentSuccess',
    defaultMessage: 'lists assigned to agent successfully'
  },
  invoiceCreationSuccess: {
    id: 'Notification.invoiceCreationSuccess',
    defaultMessage: 'Invoice created successfully'
  },
  invoiceCreationFailed: {
    id: 'Notification.invoiceCreationFailed',
    defaultMessage: 'Invoice creation faliled'
  },
  sendSMSSuccess: {
    id: 'Notification.sendSMSSuccess',
    defaultMessage: 'SMS sent successfully'
  },
  sendSMSFailed: {
    id: 'Notification.sendSMSFailed',
    defaultMessage: 'SMS sending faliled'
  },
  SMSEstimationFailed: {
    id: 'Notification.SMSEstimationFailed',
    defaultMessage: 'SMS estimation faliled'
  },

  responseAssignmentToAgentFailed: {
    id: 'Notification.responseAssignmentToAgentFailed',
    defaultMessage: 'lists assigned to agent failed'
  },
  listsAssignmentToAgentSuccess: {
    id: 'Notification.listsAssignmentToAgentSuccess',
    defaultMessage: 'lists assigned to agent successfully'
  },
  listsAssignmentToAgentFailed: {
    id: 'Notification.listsAssignmentToAgentFailed',
    defaultMessage: 'lists assigned to agent failed'
  },
  performanceovertimeText: {
    id: 'App.Text.performanceovertimeText',
    defaultMessage: 'performance over time'
  },
  performanceof: {
    id: 'App.Text.performanceof',
    defaultMessage: 'Performance of'
  },
  dateCollected: {
    id: 'App.Text.dateCollected',
    defaultMessage: 'Date Collected'
  },
  responseCount: {
    id: 'App.Text.responseCount',
    defaultMessage: 'Response count'
  },
  responseUpdateSuccess: {
    id: 'Notification.responseUpdateSuccess',
    defaultMessage: 'Response updated successfully'
  },
  responseUpdateFailure: {
    id: 'Notification.responseUpdateFailure',
    defaultMessage: 'Response update failed'
  },
  formPreferencesUpdateSuccess: {
    id: 'Notification.formPreferencesUpdateSuccess',
    defaultMessage: 'Form preferences updated successfully'
  },
  formPreferencesUpdateFailed: {
    id: 'Notification.formPreferencesUpdateFailed',
    defaultMessage: 'Form preferences update failed'
  },
  removedFavChart: {
    id: 'Notification.removedFavChart',
    defaultMessage: 'Chart removed from Dashboard'
  },
  addedFavChart: {
    id: 'Notification.addedFavChart',
    defaultMessage: 'Chart added to Dashboard'
  },
  save: {
    id: 'App.Text.Save',
    defaultMessage: 'Save'
  },
  cancel: {
    id: 'App.Text.Cancel',
    defaultMessage: 'Cancel'
  },
  Calculation: {
    id: 'App.Text.Calculation',
    defaultMessage: 'Calculation'
  },
  deleteSurvey: {
    id: 'Notification.FormDeleted',
    defaultMessage: 'Form deleted successfully'
  },

  errorPushSuccess: {
    id: 'Notification.errorPushSuccess',
    defaultMessage: 'Response successfully returned to agent'
  },
  agentRestrictStatusActive: {
    id: 'Notification.agentRestrictStatusActive',
    defaultMessage: 'Agent status set to active'
  },
  agentRestrictStatusDenied: {
    id: 'Notification.agentRestrictStatusDenied',
    defaultMessage: 'Agent status set to restricted'
  },
  agentRestrictStatusFailed: {
    id: 'Notification.agentRestrictStatusFailed',
    defaultMessage: 'Agent status change failed'
  },
  noImagesFound: {
    id: 'Notification.noImagesFound',
    defaultMessage: 'No images available for this form'
  },
  noSignaturesFound: {
    id: 'Notification.noSignaturesFound',
    defaultMessage: 'No signatures available for this form'
  },
  errorPushFail: {
    id: 'Notification.errorPushFail',
    defaultMessage: 'Error push to agent failed'
  },
  errorWithAction: {
    id: 'Notification.errorWithAction',
    defaultMessage: 'An error occured whilst performing this action. Try Again'
  },

  sessionExpired: {
    id: 'Notification.sessionExpired',
    defaultMessage: 'Session has expired'
  },
  updateFormDetails: {
    defaultMessage: 'Form details changed successfully',
    id: 'Notification.FormDetailsUpdated'
  },
  autoPublishOn: {
    defaultMessage: 'Auto publish turned on',
    id: 'Notification.AutoPublishOn'
  },
  autoPublishOff: {
    defaultMessage: 'Auto publish turned off',
    id: 'Notification.AutoPublishOff'
  },
  formPublished: {
    defaultMessage: 'Form published successfully',
    id: 'Notification.FormPublished'
  },
  formSaved: {
    defaultMessage: 'Form saved successfully',
    id: 'Notification.FormSaved'
  },
  formUnpublished: {
    defaultMessage: 'Form unpublished successfully',
    id: 'Notification.FormUnpublished'
  },
  verificationAccSuccess: {
    defaultMessage: 'Account verification successful',
    id: 'Notification.verificationAccSuccess'
  },
  verificationAccFailed: {
    defaultMessage:
      'Account verification failed or link has expired. Login to resend a verification mail.',
    id: 'Notification.verificationAccFailed'
  },
  resendEmailFailed: {
    defaultMessage: 'Resending email failed. Try again',
    id: 'Notification.resendEmailFailed'
  },
  resendEmailSuccess: {
    defaultMessage: 'Email resend successful',
    id: 'Notification.resendEmailSuccess'
  },
  cloningSuccess: {
    defaultMessage: 'Form duplicated successfully',
    id: 'Notification.cloningSuccess'
  },
  cloningFailed: {
    defaultMessage: 'Form duplication failed',
    id: 'Notification.cloningFailed'
  },
  inviteAlreadySent: {
    defaultMessage: 'An invitation has already been sent to this email',
    id: 'Notification.inviteAlreadySent'
  },
  collaboratorExists: {
    defaultMessage: 'This collaborator already exists in project',
    id: 'Notification.collaboratorExists'
  },
  collaboratorAdded: {
    defaultMessage: 'Collaborator added successfully',
    id: 'Notification.collaboratorAdded'
  },
  collaboratorAccessDenied: {
    defaultMessage: 'Collaborator does not have access to this project',
    id: 'Notification.collaboratorAccessDenied'
  },
  resetEmailSent: {
    id: 'Notification.resetEmailSent',
    defaultMessage: 'An email has been sent to your address'
  },
  resetTokenSent: {
    id: 'Notification.resetTokenSent',
    defaultMessage: 'A reset token has been sent to your phone'
  },
  minPasswordChar: {
    id: 'Notification.minPasswordChar',
    defaultMessage: 'Password must be at least 6 characters'
  },
  passwordResetMade: {
    id: 'App.Text.PasswordResetMade',
    defaultMessage: 'Your password reset request has been made!!'
  },
  passwordChanged: {
    id: 'Notification.passwordChanged',
    defaultMessage: 'Password changed successfully'
  },
  profileUpdated: {
    id: 'Notification.profileUpdated',
    defaultMessage: 'Profile details changed successfully'
  },
  preferencesUpdated: {
    id: 'Notification.preferencesUpdated',
    defaultMessage: 'Profile preferences changed successfully'
  },

  profileUpdateSuccess: {
    id: 'Notification.profileUpdateSuccess',
    defaultMessage: 'Organisation details changed successfully'
  },
  profileUpdateFailed: {
    id: 'Notification.profileUpdateFailed',
    defaultMessage: 'Organization details change failed'
  },
  signUpSuccess: {
    id: 'Notification.signUpSuccess',
    defaultMessage: 'Sign up was successful'
  },
  signUpFailed: {
    id: 'Notification.signUpFailed',
    defaultMessage: 'Sign up failed. Try again'
  },
  signInSuccess: {
    id: 'Notification.signInSuccess',
    defaultMessage: 'Sign in was successful'
  },
  userNotFound: {
    id: 'Notification.userNotFound',
    defaultMessage: 'User not found'
  },

  signUpCaptchaFailed: {
    id: 'Notification.signUpCaptchaFailed',
    defaultMessage: 'Looks like you are a robot. Try again'
  },

  verifiedSuccess: {
    id: 'Notification.verifiedSuccess',
    defaultMessage: 'User verified successfully'
  },

  verifiedFailed: {
    id: 'Notification.verifiedFailed',
    defaultMessage: 'User verification failed'
  },
  senderIdRequestFailed: {
    id: 'Notification.senderIdRequestFailed',
    defaultMessage: 'Sender ID request failed'
  },
  senderIdRequestSuccess: {
    id: 'Notification.senderIdRequestSuccess',
    defaultMessage: 'Sender ID requested successfully'
  },

  addedOn: {
    defaultMessage: 'added on',
    id: 'collaboratorList.addedOn'
  },
  addedAt: {
    defaultMessage: 'at',
    id: 'collaboratorList.addedAt'
  },
  textNo: {
    defaultMessage: 'No',
    id: 'AppText.No'
  },
  textYes: {
    defaultMessage: 'Yes',
    id: 'AppText.Yes'
  },
  formStatusUnpublished: {
    id: 'FormStatus.Unpublished',
    defaultMessage: 'Not published'
  },
  formStatusPublished: {
    id: 'FormStatus.Published',
    defaultMessage: 'Published'
  },
  formStatusNA: {
    id: 'FormStatus.NA',
    defaultMessage: 'NA'
  },
  DataCollectionTutHeader: {
    id: 'App.DataCollectionTutHeader',
    defaultMessage: 'Form created successfully'
  },
  phoneNumberPlaceholder: {
    id: 'signupForm.Phone',
    defaultMessage: 'Phone'
  },
  reportNoQuestionTitle: {
    id: 'reports.noTitle',
    defaultMessage: 'No question title'
  },
  subformRemoveBtnMsg: {
    id: 'subformSelector.RefreshMsg',
    defaultMessage: 'Remove this form'
  },
  submissionTableLabel: {
    id: 'App.FormResponsesPage.submissionTableLabel',
    defaultMessage: 'Submission Date'
  },
  responseFlagTabular: {
    id: 'App.FormResponsesPage.responseFlagTabular',
    defaultMessage: 'Status'
  },
  responseAgent: {
    id: 'App.FormResponsesPage.responseAgent',
    defaultMessage: 'Agent'
  },
  startDate: {
    id: 'App.FormResponsesPage.startDateTableLabel',
    defaultMessage: 'Start Time'
  },
  endDate: {
    id: 'App.FormResponsesPage.endDateTableLabel',
    defaultMessage: 'End Time'
  },
  hotjarHelp: {
    id: 'Hotjar.HelpUs',
    defaultMessage: 'Help us improve by sharing your feedback.'
  },
  hotjarRate: {
    id: 'Hotjar.RateUs',
    defaultMessage: 'How would you rate your experience?'
  },
  hotjarEmail: {
    id: 'Hotjar.Email',
    defaultMessage:
      'Enter your email address if you would like to receive a follow up.'
  },
  hotjarConfirm: {
    id: 'Hotjar.Confirm',
    defaultMessage: 'Thank you for sharing your feedback with us!'
  },
  seconds: {
    id: 'AppTime.seconds',
    defaultMessage: 'secs'
  },
  minutes: {
    id: 'AppTime.minutes',
    defaultMessage: 'minutes'
  },
  hours: {
    id: 'AppTime.hours',
    defaultMessage: 'hours'
  },
  days: {
    id: 'AppTime.days',
    defaultMessage: 'days'
  },
  questionTitlePlaceholder: {
    id: 'QuestionBuilder.BuilderDetails.QuestionTitlePlaceholder',
    defaultMessage: 'Enter question'
  },
  subformTitlePlaceholder: {
    id: 'QuestionBuilder.BuilderDetails.SubformTitlePlaceholder',
    defaultMessage: 'Enter title'
  },
  questionKeywordPlaceholder: {
    id: 'QuestionBuilder.BuilderDetails.questionKeywordPlaceholder',
    defaultMessage: 'Column name'
  },
  questionRegexPlaceholder: {
    id: 'QuestionBuilder.BuilderDetails.questionRegexPlaceholder',
    defaultMessage: 'Enter Regex'
  },
  questionRegexExamplePlaceholder: {
    id: 'QuestionBuilder.BuilderDetails.questionRegexExamplePlaceholder',
    defaultMessage: 'Enter Example'
  },
  questionAttestationPlaceholder: {
    id: 'QuestionBuilder.BuilderDetails.questionAttestationPlaceholder',
    defaultMessage: 'Enter attestation here...'
  },
  questionDescription: {
    defaultMessage: 'Describe your introductory text here',
    id: 'QuestionBuilder.BuilderDetails.QuestionDescription'
  },
  sectionTitlePlaceholder: {
    defaultMessage: 'Enter section title',
    id: 'QuestionBuilder.BuilderDetails.SectionTitlePlaceholder'
  },
  QuestionHintText: {
    defaultMessage: 'Enter Hint',
    id: 'App.QuestionHintText'
  },
  MaxText: {
    defaultMessage: 'Max',
    id: 'App.MaxText'
  },
  MinText: {
    defaultMessage: 'Min',
    id: 'App.MinText'
  },
  WidthText: {
    defaultMessage: 'Width',
    id: 'App.WidthText'
  },
  HeightText: {
    defaultMessage: 'Height',
    id: 'App.HeightText'
  },
  ImageFormatsTitle: {
    defaultMessage: 'Image formats (comma separated)',
    id: 'QuestionBuilder.BuilderDetails.ImageFormatsTitle'
  },
  IntroText: {
    defaultMessage: 'Introductory Text',
    id: 'App.IntroText'
  },
  ImageText: {
    defaultMessage: 'Image',
    id: 'App.ImageText'
  },
  SingleAnsText: {
    defaultMessage: 'Multiple Choice',
    id: 'App.SingleAnsText'
  },
  NumberText: {
    defaultMessage: 'Number',
    id: 'App.NumberText'
  },
  TextText: {
    defaultMessage: 'Text',
    id: 'App.TextText'
  },
  MultipleAnsText: {
    defaultMessage: 'Multiple Choice',
    id: 'App.MultipleAnsText'
  },
  SectionText: {
    defaultMessage: 'Section',
    id: 'App.SectionText'
  },
  GPSCordText: {
    defaultMessage: 'G.P.S. Cordinate',
    id: 'App.GPSCordText'
  },
  DateText: {
    defaultMessage: 'Date',
    id: 'App.DateText'
  },
  SubFormText: {
    defaultMessage: 'Sub Form',
    id: 'App.SubFormText'
  },
  PolygonText: {
    defaultMessage: 'Polygon',
    id: 'App.PolygonText'
  },
  SignatureText: {
    defaultMessage: 'Signature',
    id: 'App.SignatureText'
  },
  EmailNotExistMsg: {
    defaultMessage: 'Sorry {email} does not exist',
    id: 'App.EmailNotExistMsg'
  },
  FingerprintText: {
    defaultMessage: 'Fingerprint',
    id: 'App.FingerprintText'
  },
  OptionTreeText: {
    defaultMessage: 'Tree',
    id: 'App.OptionTreeText'
  },
  optionExists: {
    id: 'OptionsAdder.OptionExist',
    defaultMessage: 'Option already exists!'
  },
  listHasDuplicates: {
    id: 'OptionsAdder.listHasDuplicates',
    defaultMessage: 'List has duplicates'
  },
  enterOption: {
    id: 'OptionsAdder.enterOption',
    defaultMessage: 'Please enter option to be saved'
  },
  checkOthers: {
    id: 'OptionsAdder.checkOthers',
    defaultMessage:
      "You can add 'others/other' option by checking 'Accept Others'"
  },
  addOptionPlaceholder: {
    defaultMessage: "Type option and press 'enter'",
    id: 'OptionsAdder.InputPlaceholder'
  },
  addTreeInputPlaceholder: {
    defaultMessage: 'Type value here',
    id: 'Tree.addTreeInputPlaceholder'
  },
  addTreeTextareaPlaceholder: {
    defaultMessage: 'Type or copy/paste list here',
    id: 'Tree.addTreeTextareaPlaceholder'
  },
  searchResponseInputPlaceHolder: {
    defaultMessage: 'Search for a response',
    id: 'Rsponses.searchResponseInputPlaceHolder'
  },
  typeHere: {
    defaultMessage: 'Type here',
    id: 'App.Text.typeHere'
  },
  chartLabelTitleInputPlaceholder: {
    defaultMessage: 'Enter chart title',
    id: 'chartLabelTitleInputPlaceholder'
  },
  chartLabelxAxisInputPlaceholder: {
    defaultMessage: 'Enter horizontal axis label',
    id: 'chartLabelxAxisInputPlaceholder'
  },
  chartLabelyAxisInputPlaceholder: {
    defaultMessage: 'Enter vertical axis label',
    id: 'chartLabelyAxisInputPlaceholder'
  },
  editOptionValuePlaceholder: {
    defaultMessage: 'Enter label for option',
    id: 'OptionsAdder.EditValuePlaceholder'
  },
  editOptionKeyPlaceholder: {
    defaultMessage: 'Enter key for option',
    id: 'OptionsAdder.EditKeyPlaceholder'
  },
  editOptionsKModalHeader: {
    defaultMessage: 'Edit option',
    id: 'OptionsAdder.editOptionsKModalHeader'
  },
  countryCodeRequiredMsg: {
    defaultMessage: 'Number not valid, begin with country code. E.g. +233',
    id: 'SignupForm.countryCodeRequiredMsg'
  },
  proportionTitle: {
    defaultMessage: 'Proportions',
    id: 'analyticType.Proportion'
  },
  proportionDesc: {
    defaultMessage: 'Visualize the compositions of a response',
    id: 'analyticType.ProportionDesc'
  },
  relationshipTitle: {
    defaultMessage: 'Comparative Analysis',
    id: 'analyticType.Relationship'
  },
  relationshipDesc: {
    defaultMessage: 'Compare responses between different groups',
    id: 'analyticType.RelationshipDesc'
  },
  summariesTitle: {
    defaultMessage: 'Visualize Variations',
    id: 'analyticType.Summaries'
  },
  summariesDesc: {
    defaultMessage:
      'View the spread of distributions, its central value, variability and outliers',
    id: 'analyticType.SummariesDesc'
  },
  descriptiveAnalysisTitle: {
    defaultMessage: 'Summary Statistics',
    id: 'analyticType.descriptiveAnalysisTitle'
  },
  descriptiveAnalysisDesc: {
    defaultMessage:
      'View descriptions of your data such as averages, counts, minimum and maximum values of your responses',
    id: 'analyticType.descriptiveAnalysisDesc'
  },
  trendsTitle: {
    defaultMessage: 'Trend Analysis',
    id: 'analyticType.Trends'
  },
  trendsDesc: {
    defaultMessage: 'Compare changes over time for various responses',
    id: 'analyticType.TrendsDesc'
  },
  gotoDash: {
    defaultMessage: 'Back to analytics',
    id: 'analytic.gotoDash'
  },
  selectQuestionProportionMsg: {
    defaultMessage: 'Select a question',
    id: 'App.Message.selectQuestion'
  },
  selectQuestionRelationMsgxAxis: {
    defaultMessage: 'Which response contains your time?',
    id: 'App.Message.selectQuestionRelationMsgxAxis'
  },
  selectQuestionRelationMsgSeries: {
    defaultMessage: 'Select another response',
    id: 'App.Message.selectQuestionRelationMsgSeries'
  },
  selectQuestionComparisonMsgxAxis: {
    defaultMessage: 'Group analysis by these responses',
    id: 'App.Message.selectQuestionComparisonMsgxAxis'
  },
  dataTypeSelectedLabel: {
    defaultMessage: 'Generate chart using: ',
    id: 'App.Message.dataTypeSelectedLabel'
  },
  multiSelectYAxis: {
    defaultMessage: 'Select at least two responses',
    id: 'App.Message.multiSelectYAxis'
  },
  selectQuestionComparisonMsgyAxis: {
    defaultMessage: 'Select a response',
    id: 'App.Message.selectQuestionComparisonMsgyAxis'
  },
  parametersSelectTitle: {
    defaultMessage: 'Select parameters',
    id: 'App.Message.parametersSelectTitle'
  },
  selectQuestionComparisonMsgSeries: {
    defaultMessage: 'Select another response to compare with',
    id: 'App.Message.selectQuestionComparisonMsgSeries'
  },
  selectQuestionSummaryMsgxAxis: {
    defaultMessage: 'Group data by',
    id: 'App.Message.selectQuestionSummaryMsgxAxis'
  },
  selectQuestionSummaryMsgyAxis: {
    defaultMessage: 'Select responses to be plotted',
    id: 'App.Message.selectQuestionSummaryMsgyAxis'
  },
  clickBelowToSelect: {
    defaultMessage: 'Click below to select question',
    id: 'App.Message.clickBelowToSelect'
  },
  clickBelowToSelectParams: {
    defaultMessage: 'Click below to select/deselect parameters',
    id: 'App.Message.clickBelowToSelectParams'
  },
  clickToReveal: {
    defaultMessage: 'Click to reveal questions',
    id: 'App.Message.clickToReveal'
  },
  enterOptionOnly: {
    defaultMessage: 'Enter Option',
    id: 'App.Message.enterOptionOnly'
  },
  AddColumnTitle: {
    defaultMessage: 'Double click to add title',
    id: 'App.Message.AddColumnTitle'
  },
  optionParamsLabelSum: {
    defaultMessage: 'Sum',
    id: 'App.optionParamsLabelSum'
  },
  optionParamsDescSum: {
    defaultMessage: 'Total Value',
    id: 'App.optionParamsDescSum'
  },
  optionParamsLabelCount: {
    defaultMessage: 'Count',
    id: 'App.optionParamsLabelCount'
  },
  optionParamsDescCount: {
    defaultMessage: 'Number of occurrances in your response',
    id: 'App.optionParamsDescCount'
  },
  optionParamsLabelMode: {
    defaultMessage: 'Mode',
    id: 'App.optionParamsLabelMode'
  },
  optionParamsDescMode: {
    defaultMessage: 'Appears most often in your response',
    id: 'App.optionParamsDescMode'
  },
  optionParamsLabelMaximum: {
    defaultMessage: 'Maximum',
    id: 'App.optionParamsLabelMaximum'
  },
  optionParamsDescMaximum: {
    defaultMessage: 'Highest value in your response',
    id: 'App.optionParamsDescMaximum'
  },
  optionParamsLabelMinimum: {
    defaultMessage: 'Minimum',
    id: 'App.optionParamsLabelMinimum'
  },
  optionParamsDescMinimum: {
    defaultMessage: 'Least value in your response',
    id: 'App.optionParamsDescMinimum'
  },
  optionParamsLabelMedian: {
    defaultMessage: 'Median',
    id: 'App.optionParamsLabelMedian'
  },
  optionParamsDescMedian: {
    defaultMessage: 'Central Value in your response',
    id: 'App.optionParamsDescMedian'
  },
  optionParamsLabelAverage: {
    defaultMessage: 'Average',
    id: 'App.optionParamsLabelAverage'
  },
  optionParamsDescAverage: {
    defaultMessage: 'Calculated central value in your response',
    id: 'App.optionParamsDescAverage'
  },
  optionParamsLabelStD: {
    defaultMessage: 'Standard Deviation',
    id: 'App.optionParamsLabelStD'
  },
  optionParamsDescStD: {
    defaultMessage: 'How far your response is from the average',
    id: 'App.optionParamsDescStD'
  },
  analyticTypeDetailsProportionLabel: {
    defaultMessage: 'Proportions',
    id: 'App.analyticTypeDetailsProportionLabel'
  },
  analyticTypeDetailsProportionDesc: {
    defaultMessage: 'View a chart for Proportion on the form.',
    id: 'App.analyticTypeDetailsProportionDesc'
  },
  analyticTypeDetailsTrendOverTimeLabel: {
    defaultMessage: 'Trend Analysis',
    id: 'App.analyticTypeDetailsTrendOverTimeLabel'
  },
  analyticTypeDetailsTrendOverTimeDesc: {
    defaultMessage: 'View a chart for trend Analysis on the form.',
    id: 'App.analyticTypeDetailsTrendOverTimeDesc'
  },
  analyticTypeDetailsRelationshipLabel: {
    defaultMessage: 'Comparative Analysis',
    id: 'App.analyticTypeDetailsRelationshipLabel'
  },
  analyticTypeDetailsRelationshipDesc: {
    defaultMessage: 'View a chart for Comparative Analysis on the form.',
    id: 'App.analyticTypeDetailsRelationshipDesc'
  },
  analyticTypeDetailsSummariesLabel: {
    defaultMessage: 'Visualize Variations',
    id: 'App.analyticTypeDetailsSummariesLabel'
  },
  analyticTypeDetailsSummariesDesc: {
    defaultMessage: 'View a chart for Visual Variations on the form.',
    id: 'App.analyticTypeDetailsSummariesDesc'
  },
  analyticTypeDetailsdescAnalysisLabel: {
    defaultMessage: 'Descriptive Statistics',
    id: 'App.analyticTypeDetailsdescAnalysisLabel'
  },
  analyticTypeDetailsdescAnalysisDesc: {
    defaultMessage: 'View a list of descriptive analysis on the form.',
    id: 'App.analyticTypeDetailsdescAnalysisDesc'
  },
  responseSummaryLabel: {
    defaultMessage: 'Response Summary',
    id: 'App.ReportType.responseSummaryLabel'
  },
  responseSummaryDescription: {
    defaultMessage:
      'View  the counts of your responses. Get informed of the number of times a response occured and skipped in your data set',
    id: 'App.ReportType.responseSummaryDescription'
  },
  agentLocationLabel: {
    defaultMessage: 'Agent Location',
    id: 'App.ReportType.agentLocationLabel'
  },
  agentLocationDescription: {
    defaultMessage:
      'Know the exact last location of your agents. This allow you to keep an eye on your agents wherever they are.',
    id: 'App.ReportType.agentLocationDescription'
  },
  agentPerformanceLabel: {
    defaultMessage: 'Agent Performance',
    id: 'App.ReportType.agentPerformanceLabel'
  },
  agentPerformanceDescription: {
    defaultMessage:
      'Analyze the performance of your agents over time. Easily identify best and worst performing agents with this report',
    id: 'App.ReportType.agentPerformanceDescription'
  },
  reviewActionAccept: {
    defaultMessage: 'No Action',
    id: 'App.reviewActionAccept'
  },
  reviewActionReject: {
    defaultMessage: 'Reject',
    id: 'App.text.Reject'
  },
  reviewActionOther: {
    defaultMessage: 'Reject with a comment',
    id: 'App.reviewActionOther'
  },
  submittedBy: {
    defaultMessage: 'Submitted by',
    id: 'App.DetailedResponse.submittedBy'
  },
  dateSubmitted: {
    defaultMessage: 'Date submitted',
    id: 'App.DetailedResponse.dateSubmitted'
  },
  Responselocation: {
    defaultMessage: 'Response location (GPS)',
    id: 'App.DetailedResponse.Responselocation'
  },
  Responseduration: {
    defaultMessage: 'Response duration',
    id: 'App.DetailedResponse.Responseduration'
  },
  seeLog: {
    defaultMessage: 'See activity log',
    id: 'App.DetailedResponse.seeLog'
  },
  errorManagement: {
    defaultMessage: 'Error Management',
    id: 'App.DetailedResponse.errorManagement'
  },
  comments: {
    defaultMessage: 'Comments',
    id: 'App.Text.Comments'
  },
  addtionalComment: {
    defaultMessage: 'Additional comments (if any)',
    id: 'App.DetailedResponse.addtionalComment'
  },
  enterComment: {
    defaultMessage: 'Enter comment',
    id: 'App.DetailedResponse.enterComment'
  },
  addedBy: {
    defaultMessage: 'Added by',
    id: 'App.DetailedResponse.addedBy'
  },
  status: {
    defaultMessage: 'Status',
    id: 'App.Text.Status'
  },
  close: {
    defaultMessage: 'Close',
    id: 'App.Text.Close'
  },
  addedcomment: {
    defaultMessage: 'added a comment on',
    id: 'App.DetailedResponse.addedcomment'
  },
  changedStatus: {
    defaultMessage: 'changed the status from',
    id: 'App.DetailedResponse.changedStatus'
  },
  on: {
    defaultMessage: 'on',
    id: 'App.Text.On'
  },
  to: {
    defaultMessage: 'to',
    id: 'App.Text.To'
  },
  activityLog: {
    defaultMessage: 'Activity log',
    id: 'App.DetailedResponse.activityLog'
  },
  noActivityLog: {
    defaultMessage: 'No activity log',
    id: 'App.DetailedResponse.noActivityLog'
  },
  commentLog: {
    defaultMessage: 'Comment log',
    id: 'App.DetailedResponse.commentLog'
  },
  noComments: {
    defaultMessage: 'No comments',
    id: 'App.DetailedResponse.noComments'
  },
  formErrorMsgNotValidPhoneNumber: {
    defaultMessage: 'Invalid phone number',
    id: 'App.Form.formErrorMsgNotValidPhoneNumber'
  },
  formErrorMsgMismatchPassword: {
    defaultMessage: 'Passwords do not match',
    id: 'App.Form.formErrorMsgMismatchPassword'
  },
  formEditSaveSuccess: {
    defaultMessage: 'Edited response saved successfully',
    id: 'App.Responses.formEditSuccess'
  },
  formEditSaveFailed: {
    defaultMessage: 'Saving edited response failed',
    id: 'App.Responses.formEditFailed'
  },
  createReviewExerciseSuccess: {
    defaultMessage: 'Review exercise created successfully',
    id: 'App.Responses.createReviewExerciseSuccess'
  },
  createReviewExerciseFailed: {
    defaultMessage: 'Review exercise creation failed',
    id: 'App.Responses.createReviewExerciseFailed'
  },
  fetchReviewExercisesFailed: {
    defaultMessage: 'Review exercises fetch failed',
    id: 'App.Responses.fetchReviewExercisesFailed'
  },
  fetchReviewExerciseFailed: {
    defaultMessage: 'Review exercise fetch failed',
    id: 'App.Responses.fetchReviewExerciseFailed'
  },
  declineInviteFailed: {
    defaultMessage: 'declining invite failed',
    id: 'App.Responses.declineInviteFailed'
  },
  approveInviteFailed: {
    defaultMessage: 'declining invite failed',
    id: 'App.Responses.approveInviteFailed'
  },
  networkError: {
    id: 'Notification.networkError',
    defaultMessage: 'No internet connection'
  },
  downloadTemplate: {
    id: 'App.Button.DownloadTemplate',
    defaultMessage: 'Download template'
  },
  downloadExcelProvided: {
    id: 'App.Text.DownloadExcelProvided',
    defaultMessage: 'Download the excel template provided'
  },
  howToUpload: {
    id: 'App.Text.HowToUpload',
    defaultMessage: 'How to upload file'
  },
  importNote: {
    id: 'App.Text.ImportNote',
    defaultMessage: 'Note: A phone number or email address is required for successfully adding a person.'
  },
  importPopulateExcel: {
    id: 'App.Text.ImportPopulateExcel',
    defaultMessage: 'Populate the downloaded excel spreadsheet with details of your people.'
  },
  moreFields: {
    id: 'App.Text.MoreFields',
    defaultMessage: 'More fields'
  },
  UserFormTitle: {
    id: 'Administrators.Form.Title',
    defaultMessage: 'Add User'
  },
  Success: {
    id: 'Success', defaultMessage: 'Success'
  },
  Failed: {
    id: 'Failed', defaultMessage: 'Failed'
  },
  SendVerificationMailSuccess: {
    id: 'SendVerificationMailSuccess',
    defaultMessage: 'Verification mail sent to {email}'
  },
  SendVerificationMailFail: {
    id: 'SendVerificationMailFail',
    defaultMessage: 'Unable to send verification mail. Please try again'
  },
  SavingFailed: {
    id: 'SavingFailed',
    defaultMessage: 'Saving Failed'
  },
  user_updateRole_success: {
    id: 'user_updateRole_success',
    defaultMessage: 'User role updated successfully',
  },
  user_updateRole_failed: {
    id: 'user_updateRole_failed',
    defaultMessage: 'Failed to update user role',
  },
  'Admin.Delete.Success': {
    id: 'Admin.Delete.Success',
    defaultMessage: 'Admin has been revoked',
  },
  'Admin.Delete.Failed': {
    id: 'Admin.Delete.Failed',
    defaultMessage: 'Unable to revoke admin'
  },
  'Administrators.Revoke.Title': {
    id: 'Administrators.Revoke.Title',
    defaultMessage: 'Revoke Administrator Access'
  },
  'Invitation.Send.Success': {
    id: 'Invitation.Send.Success',
    defaultMessage: 'Invitation has been sent'
  },
  'Invitation.Send.Failed': {
    id: 'Invitation.Send.Failed',
    defaultMessage: 'Invitation sending has failed'
  },
  'Administrators.Form.Fullname': {
    id: 'Administrators.Form.Fullname',
    defaultMessage: 'Fullname'
  },
  'Administrators.Form.Fullname.Required': {
    id: 'Administrators.Form.Fullname.Required',
    defaultMessage: 'Fullname is required'
  },
  'Administrators.Form.Fullname.Min2': {
    id: 'Administrators.Form.Fullname.Min2',
    defaultMessage: 'Fullname must be at least 2 characters'
  },
  'Administrators.Form.Fullname.Max50': {
    id: 'Administrators.Form.Fullname.Max50',
    defaultMessage: 'Fullname cannot be longer than 50 characters'
  },
  'Administrators.Form.Fullname.Placeholder': {
    id: 'Administrators.Form.Fullname.Placeholder',
    defaultMessage: 'Administrator\'s Fullname'
  },
  'Label.EmailAddress': {
    id: 'Label.EmailAddress',
    defaultMessage: 'Email address'
  },
  'Administrators.Form.Email.Required': {
    id: 'Administrators.Form.Email.Required',
    defaultMessage: 'Email address is required'
  },
  'Administrators.Form.Email.Invalid': {
    id: 'Administrators.Form.Email.Invalid',
    defaultMessage: 'Email address is invalid'
  },
  'Administrators.Form.Email.Placeholder': {
    id: 'Administrators.Form.Email.Placeholder',
    defaultMessage: 'Administrator\'s email address'
  },
  'auth_form_email': {
    id: 'auth_form_email',
    defaultMessage: 'Email address'
  },
  'auth_form_username': {
    id: 'auth_form_username',
    defaultMessage: 'Email address'
  },
  'auth_form_password': {
    id: 'auth_form_password',
    defaultMessage: 'Password'
  },
  'Label.Organisation': {
    id: 'Label.Organisation',
    defaultMessage: 'Organisation name'
  },
  'Label.Industry': {
    id: 'Label.Industry',
    defaultMessage: 'Industry'
  },
  'Label.FirstName': {
    id: 'Label.FirstName',
    defaultMessage: 'First name'
  },
  'Label.LastName': {
    id: 'Label.LastName',
    defaultMessage: 'Last name'
  },
  'Label.Email': {
    id: 'Label.Email',
    defaultMessage: 'Email address'
  },
  'auth_form_country': {
    id: 'auth_form_country',
    defaultMessage: 'Country'
  },
  'Label.PhoneNumber': {
    id: 'Label.PhoneNumber',
    defaultMessage: 'Phone number'
  },
  'Label.Password': {
    id: 'Label.Password',
    defaultMessage: 'Password'
  },
  auth_form_confirm_password: {
    id: 'auth_form_confirm_password',
    defaultMessage: 'Confirm password'
  },
  confirm_password: {
    id: 'confirm_password',
    defaultMessage: 'Please confirm password'
  },
  SaveOrganisationSuccess: {
    id: 'SaveOrganisationSuccess',
    defaultMessage: 'Organisation {name} saved'
  },
  SaveOrganisationFail: {
    id: 'SaveOrganisationFail',
    defaultMessage: 'Unable to save {name}. Please try again'
  },
  SaveOrganisationLogoSuccess: {
    id: 'SaveOrganisationLogoSuccess',
    defaultMessage: 'Organisation logo saved'
  },
  SaveOrganisationLogoFail: {
    id: 'SaveOrganisationLogoFail',
    defaultMessage: 'Unable to save logo. Please try again'
  },
  required_industry: {
    id: 'required_industry',
    defaultMessage: 'Please provide industry'
  },
  SaveSettingsFailed: {
    id: 'SaveSettingsFailed',
    defaultMessage: 'Unable to save {name}. Please try again'
  },
  SaveProfileImageSuccess: {
    id: 'SaveProfileImageSuccess',
    defaultMessage: 'Profile image saved'
  },
  SaveProfileImageFailed: {
    id: 'SaveProfileImageFailed',
    defaultMessage: 'Unable to save Profile image. Please try again'
  }
});
