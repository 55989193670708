import { SET_PAGE_TITLE, SET_SIDER_WIDTH, SET_SITE_LANG } from '../_types';
import { setCookie } from '../../utils/helpers';

export function setPageTitle(title) {
    return dispatch => {
        dispatch({ type: SET_PAGE_TITLE, title });
    }
};

export function setLang(lang) {
    return dispatch => {
        setCookie('site_lang', lang);
        dispatch({ type: SET_SITE_LANG, lang });
    }
};

export function setSiderWidth(width) {
    return dispatch => {
        dispatch({ type: SET_SIDER_WIDTH, width });
    }
};